import {
  useGetLearningPath,
  useGetLearningPathProgress,
} from "../api/primio/primioComponents";
import UserModel from "../models/user/user.model";
import { useSelector } from "react-redux";
import { getAllUserGroupsPerUser } from "../redux/selectors/user-group/user-group.selector";
import { useEffect, useState } from "react";
import { UserGroupRoles } from "../../constants";

interface LearningPathDetailProgressResponse {
  learningPathType: string;
  state: string;
  users?: {
    [userUid: string]: {
      username: string;
      startedAt: string;
      completed?: {
        //contains more data but we don't need it
        completedAt: string;
      };
    };
  };
}

export interface LearningPathDetailReportUser {
  userUid: string;
  username: string;
  startedAt?: string;
  completedAt?: string;
  name: string;
  email: string;
  userGroups: string[];
  hasAccess: boolean;
  hasProgress: boolean;
  learningPathTitle: string;
  learningPathUid: string;
}

interface LearningPathDetailReportSummary {
  notStarted: number;
  started: number;
  completed: number;
  total: number;
}

export default function useLearningPathDetailReport(
  learningPathUid: string,
  userGroups?: string[],
) {
  const { data: learningPathProgress } =
    useGetLearningPathProgress<LearningPathDetailProgressResponse>({
      pathParams: { learningPathUid },
    });
  const { data: learningPathDetail } = useGetLearningPath({
    pathParams: { learningPathUid },
  });
  const users: UserModel[] = useSelector(getAllUserGroupsPerUser);
  const [learningPathProgressUsers, setLearningPathProgressUsers] = useState<
    LearningPathDetailReportUser[]
  >([]);
  const [learningPathReportSummary, setLearningPathReportSummary] =
    useState<LearningPathDetailReportSummary>({
      notStarted: 0,
      started: 0,
      completed: 0,
      total: 0,
    });

  useEffect(() => {
    if (!learningPathDetail || !learningPathProgress || users.length === 0) {
      return;
    }

    // get users who have made progress on this learningPath
    const learningPathProgressUsers: LearningPathDetailReportUser[] = [];
    if (learningPathProgress.users) {
      Object.entries(learningPathProgress.users).forEach(([userUid, user]) => {
        const foundUser = users.find((u) => u.sub === userUid);

        if (!foundUser) {
          // dont have user data, skip.
          return;
        }

        // check if user has access to this learningPath through userGroups
        let hasAccess = false;
        if (learningPathDetail.userGroups.length === 0) {
          // if no userGroups, then all users have access
          hasAccess = true;
        } else {
          // check if user is an admin or primio support, if so, they can access all learningPaths
          if (foundUser.userGroups.length === 1) {
            hasAccess = UserGroupRoles.includes(foundUser.userGroups[0]);
          }

          // if user is not an admin or primio support, check if they have access to this learningPath
          if (!hasAccess) {
            hasAccess = foundUser.userGroups.some((group) =>
              learningPathDetail.userGroups.includes(group),
            );
          }
        }

        learningPathProgressUsers.push({
          userUid,
          hasAccess,
          username: user.username,
          startedAt: user.startedAt,
          completedAt: user.completed?.completedAt,
          name: foundUser.name,
          email: foundUser.email,
          userGroups: foundUser.userGroups,
          hasProgress: true,
          learningPathTitle: learningPathDetail.title,
          learningPathUid: learningPathDetail.learningPathUid,
        });
      });
    }

    // Get users who have access to this learningPath but don't have progress
    const otherUsers: UserModel[] = [];
    users.forEach((user) => {
      const foundUser = learningPathProgressUsers.find(
        (reportUser) => reportUser.userUid === user.sub,
      );

      if (!foundUser) {
        otherUsers.push(user);
      }
    });

    if (learningPathDetail.userGroups.length === 0) {
      // if no userGroups, then all users have access
      otherUsers.forEach((user) => {
        learningPathProgressUsers.push({
          userUid: user.sub,
          hasAccess: true,
          username: user.username,
          startedAt: undefined,
          completedAt: undefined,
          name: user.name,
          email: user.email,
          userGroups: user.userGroups,
          hasProgress: false,
          learningPathTitle: learningPathDetail.title,
          learningPathUid: learningPathDetail.learningPathUid,
        });
      });
    } else {
      // if userGroups are defined, then only users in userGroups have access
      const filteredUsers = otherUsers.filter((user) =>
        user.userGroups.some((group) =>
          learningPathDetail.userGroups.includes(group),
        ),
      );
      filteredUsers.forEach((user) => {
        learningPathProgressUsers.push({
          userUid: user.sub,
          hasAccess: true,
          username: user.username,
          startedAt: undefined,
          completedAt: undefined,
          name: user.name,
          email: user.email,
          userGroups: user.userGroups,
          hasProgress: false,
          learningPathTitle: learningPathDetail.title,
          learningPathUid: learningPathDetail.learningPathUid,
        });
      });
    }

    setLearningPathProgressUsers(learningPathProgressUsers);
  }, [learningPathDetail, learningPathProgress, users]);

  useEffect(() => {
    const filteredUsers = learningPathProgressUsers.filter((user) => {
      if (userGroups && userGroups.length > 0) {
        return user.userGroups.some((group) => userGroups.includes(group));
      }

      return user;
    });

    setLearningPathReportSummary({
      notStarted: filteredUsers.filter((user) => !user.hasProgress).length,
      started: filteredUsers.filter(
        (user) => user.hasProgress && user.startedAt && !user.completedAt,
      ).length,
      completed: filteredUsers.filter((user) => user.completedAt).length,
      total: filteredUsers.length,
    });
  }, [learningPathProgressUsers, userGroups]);

  return {
    learningPathProgressUsers,
    learningPathReportSummary,
  };
}
