import React, { useState } from "react";
import { Link } from "react-router-dom";
import { RoutesEnum } from "../../../constants/routes/app.route";
import { Table, Tooltip } from "antd";
import useLearningPathDetailReport, {
  LearningPathDetailReportUser,
} from "../../../core/hooks/useLearningPathDetailReport";
import { DownloadOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import { GroupTags } from "../group-tag";
import createDateColumn from "./columns/date-column";
import DownloadCertificateButton from "../download-certificate-button/download-certificate-button";
import GetColumnSearchProps from "../../../core/utils/get-column-search-props.utils";
import { useGetUserGroups } from "../../../core/api/primio/primioComponents";
import SelectionContextMenu from "../selection-context-menu/selection-context-menu";
import useDownloadCertificates from "../../../core/hooks/use-download-certificates";

const LearningPathReportTable = ({
  learningPathUid,
}: {
  learningPathUid: string;
}) => {
  const { learningPathProgressUsers } =
    useLearningPathDetailReport(learningPathUid);
  const { t } = useTranslation();
  const [userGroupAcl, setUserGroupAcl] = useState<string[]>();
  const { data: userGroups = [] } = useGetUserGroups({
    queryParams: { shouldExcludeUsers: true },
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const { downloadCertificates } = useDownloadCertificates();

  return (
    <>
      <SelectionContextMenu
        selectedRowKeys={selectedRowKeys}
        onClearSelection={() => setSelectedRowKeys([])}
        actions={[
          {
            key: "1",
            label: (selectedRowKeys) => {
              const count = learningPathProgressUsers.filter(
                (u) => selectedRowKeys.includes(u.userUid) && !!u.completedAt,
              ).length;

              return `${Capitalize(
                t("translations:common.download_x", {
                  item: t("translations:common.certificate", { count }),
                }),
              )} (${count})`;
            },
            icon: <DownloadOutlined />,
            disabled: (selectedRowKeys) =>
              learningPathProgressUsers.filter(
                (u) => selectedRowKeys.includes(u.userUid) && !!u.completedAt,
              ).length === 0,
            onClick: async (selectedRowKeys) => {
              const data = learningPathProgressUsers.filter(
                (u) => selectedRowKeys.includes(u.userUid) && !!u.completedAt,
              );

              if (data.length === 0) {
                return;
              }

              await downloadCertificates(
                data.map((u) => ({
                  userUid: u.userUid,
                  learningPathUid: u.learningPathUid,
                })),
                `${t("translations:common.certificate_plural")}.zip`,
              );
            },
          },
        ]}
      />

      <Table
        dataSource={learningPathProgressUsers}
        rowSelection={{
          selections: [
            Table.SELECTION_ALL,
            Table.SELECTION_INVERT,
            Table.SELECTION_NONE,
          ],
          selectedRowKeys,
          onChange: setSelectedRowKeys,
        }}
        size="small"
        pagination={{
          showSizeChanger: true,
        }}
        onChange={(_pagination, filters) => {
          setUserGroupAcl(filters.userGroups as string[]);
        }}
        rowKey={(record) => record.userUid}
        columns={[
          {
            title: Capitalize(t("translations:form.items.name.label")),
            dataIndex: "name",
            ...GetColumnSearchProps({ dataIndex: "name" }),
            render: (value, record) => (
              <Link
                to={RoutesEnum.USER_DETAIL.replace(
                  ":username",
                  record.username,
                )}
              >
                <span
                  title={value}
                  style={{
                    maxWidth: 180,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    display: "inline-block",
                  }}
                >
                  {value}
                </span>
              </Link>
            ),
          },
          {
            title: Capitalize(t("translations:form.items.email.label")),
            dataIndex: "email",
            ...GetColumnSearchProps({ dataIndex: "email" }),
          },
          {
            title: Capitalize(t("containers.user-groups.key_plural")),
            dataIndex: "userGroups",
            filteredValue: userGroupAcl || null,
            onFilter: (value, record) => {
              if (!userGroupAcl) {
                return true;
              }

              let includes = false;

              if (
                userGroupAcl.includes(
                  Capitalize(t("translations:common.no-groups")),
                )
              ) {
                includes = record.userGroups.length === 0;
              }

              if (!includes) {
                includes = record.userGroups.some((u) =>
                  userGroupAcl?.includes(u),
                );
              }

              return includes;
            },
            filters: [
              {
                groupType: {
                  GroupName: Capitalize(t("translations:common.no-groups")),
                },
              },
              ...userGroups,
            ].map((group) => ({
              text: group.groupType.GroupName,
              value: group.groupType.GroupName,
            })),
            render: (value: string[]) => <GroupTags groupNames={value} />,
          },
          createDateColumn<LearningPathDetailReportUser>({
            title: Capitalize(t("translations:form.items.started-at.label")),
            dataIndex: "startedAt",
          }),
          createDateColumn<LearningPathDetailReportUser>({
            title: Capitalize(t("translations:form.items.completed-at.label")),
            dataIndex: "completedAt",
          }),
          {
            dataIndex: "hasAccess",
            width: 40,
            render: (value: boolean) =>
              !value && (
                <Tooltip
                  title={t(
                    "translations:errors.user.no-access-to-learning-path",
                  )}
                >
                  <ExclamationCircleOutlined style={{ color: "red" }} />
                </Tooltip>
              ),
          },
          {
            title: Capitalize(t("translations:common.certificate")),
            width: 100,
            render: (_value, record) => {
              return (
                <div style={{ textAlign: "right" }}>
                  {!!record.completedAt && (
                    <DownloadCertificateButton
                      userName={record.name}
                      userUid={record.userUid}
                      learningPathTitle={record.learningPathTitle}
                      learningPathUid={record.learningPathUid}
                    />
                  )}
                </div>
              );
            },
          },
        ]}
      />
    </>
  );
};

export default LearningPathReportTable;
