import React, { useEffect, useState } from "react";
import { BaseLearningPathContent } from "../../../core/api/primio/primioSchemas";
import { useGetPlaybook } from "../../../core/api/primio/primioComponents";
import { useLearningPath } from "../../../core/providers/learning-path.provider";

import { Alert, Card } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const LearningPathContentListItem = ({
  content,
}: {
  content: BaseLearningPathContent;
}) => {
  const { t } = useTranslation();
  const { data: playbook, isFetching } = useGetPlaybook({
    pathParams: { playbookUid: content.contentUid },
  });
  const { removeContent, learningPath } = useLearningPath();
  const [isVisibleForUser, setIsVisibleForUser] = useState(true);

  useEffect(() => {
    if (!learningPath || !playbook) {
      return;
    }

    let isVisible = false;

    if (playbook.userGroupAcl.length === 0) {
      isVisible = true;
    }

    if (!isVisible) {
      playbook.userGroupAcl.forEach((userGroup) => {
        if (isVisible) {
          return;
        }

        isVisible = learningPath.userGroups.includes(userGroup);
      });
    }

    setIsVisibleForUser(isVisible);
  }, [playbook, learningPath]);

  if (isFetching && !playbook) return <p>Loading...</p>;

  if (!playbook) {
    return <p>Module not found</p>;
  }

  const actions: React.ReactNode[] = [];
  if (learningPath.state === "CREATED") {
    actions.push(
      <DeleteOutlined
        onClick={() => removeContent(content.contentUid)}
        key="delete"
        style={{
          color: "var(--error-color)",
          cursor: "pointer",
        }}
      />,
    );
  }

  return (
    <Card hoverable actions={actions}>
      <Card.Meta title={playbook?.title} description={playbook?.description} />
      {!isVisibleForUser && (
        <Alert
          style={{ marginTop: 32 }}
          type={"error"}
          banner
          description={t(
            "translations:errors.learning-path.content-user-group.label",
          )}
        />
      )}
    </Card>
  );
};

export default LearningPathContentListItem;
