import React, { useEffect, useState } from "react";
import { Category } from "../../../core/api/primio/primioSchemas";
import {
  useAddPlaybookToCategory,
  useCreateCategory,
  useDeleteCategory,
  useGetCategories,
  useRemovePlaybookFromCategory,
} from "../../../core/api/primio/primioComponents";
import { Dropdown, Modal, Typography } from "antd";
import { useQueryClient } from "@tanstack/react-query";
import { Capitalize } from "../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";
import { ModalNames, useModal } from "../../../core/providers/modal.provider";
import InlineAttributeManager from "../inline-attribute-manager/inline-attribute-manager";
import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { RoutesEnum } from "../../../constants/routes/app.route";
import { useHistory } from "react-router-dom";
import { interactiveText } from "../../styles/typography";

const InlineCategoryManager = (props: {
  categories: Category[];
  playbookUid: string;
  disabled?: boolean;
}) => {
  const { data: allCategories = [] } = useGetCategories({});
  const [categories, setCategories] = useState(props.categories);
  const { t } = useTranslation();
  const qc = useQueryClient();
  const { openModal } = useModal();
  const history = useHistory();

  const { mutateAsync: removePlaybookFromCategory } =
    useRemovePlaybookFromCategory();
  const { mutateAsync: addPlaybookToCategory } = useAddPlaybookToCategory();
  const { mutateAsync: createCategory } = useCreateCategory();
  const { mutateAsync: deleteCategory } = useDeleteCategory();

  useEffect(() => {
    setCategories(props.categories);
  }, [props.categories]);

  const handleAddPlaybookToCategory = async (category: Category) => {
    // prevent adding duplicate categories
    if (categories.find((c) => c.categoryUid === category.categoryUid)) {
      return;
    }

    // optimistically add category
    setCategories([...categories, category]);

    // add category to playbook
    await addPlaybookToCategory({
      pathParams: {
        playbookUid: props.playbookUid,
        categoryUid: category.categoryUid,
      },
    });
    await qc.invalidateQueries(["v2", "playbooks"]);
    await qc.invalidateQueries(["categories"]);
  };

  const handleRemovePlaybookFromCategory = async (category: Category) => {
    // optimistically remove category
    setCategories(
      categories.filter((c) => c.categoryUid !== category.categoryUid),
    );

    // remove category from playbook
    await removePlaybookFromCategory({
      pathParams: {
        playbookUid: props.playbookUid,
        categoryUid: category.categoryUid,
      },
    });
    await qc.invalidateQueries(["v2", "playbooks"]);
    await qc.invalidateQueries(["categories"]);
  };

  const handleCreateCategory = async (title: string) => {
    await createCategory({
      body: {
        title,
        playbookUids: [props.playbookUid],
      },
    });
    await qc.invalidateQueries(["v2", "playbooks"]);
    await qc.invalidateQueries(["categories"]);
  };

  const handleDeleteCategory = async (category: Category) => {
    Modal.confirm({
      title: Capitalize(
        t("translations:errors.warnings.check-delete", {
          item: t("translations:containers.categories.key"),
        }),
      ),
      onOk: async () => {
        await deleteCategory({
          pathParams: {
            categoryUid: category.categoryUid,
          },
        });
        await qc.invalidateQueries(["v2", "playbooks"]);
        await qc.invalidateQueries(["categories"]);
      },
      cancelText: Capitalize(t("common.cancel")),
      okText: Capitalize(t("common.delete")),
    });
  };

  const handleClickCategory = (category: Category) => {
    const route = RoutesEnum.CATEGORIES_DETAIL as string;
    history.push(route.replace(":uid", category.categoryUid));
  };

  return (
    <InlineAttributeManager<Category>
      data={categories}
      allData={allCategories}
      label={Capitalize(
        t("translations:common-x.choose-or-create", {
          item: t("translations:containers.categories.key"),
        }),
      )}
      renderItem={(category) => (
        <Typography.Text style={interactiveText}>
          {category.title}
        </Typography.Text>
      )}
      keyExtractor={(category) => category.categoryUid}
      onCreate={handleCreateCategory}
      onAdd={handleAddPlaybookToCategory}
      onRemove={handleRemovePlaybookFromCategory}
      onClick={handleClickCategory}
      disabled={props.disabled}
      searchKey={"title"}
      renderItemExtra={(category) => (
        <Dropdown
          trigger={["click"]}
          arrow
          menu={{
            items: [
              {
                key: "show",
                label: Capitalize(t("common.show")),
                icon: <EyeOutlined />,
                onClick: () => {
                  const route = RoutesEnum.CATEGORIES_DETAIL as string;
                  history.push(route.replace(":uid", category.categoryUid));
                },
              },
              {
                key: "edit",
                label: Capitalize(t("common.edit")),
                icon: <EditOutlined />,
                onClick: () =>
                  openModal(ModalNames.CREATE_CATEGORY, {
                    category,
                  }),
              },
              {
                key: "delete",
                label: Capitalize(t("common.delete")),
                icon: <DeleteOutlined />,
                danger: true,
                onClick: () => handleDeleteCategory(category),
              },
            ],
          }}
        >
          <div style={{ cursor: "pointer" }}>
            <EllipsisOutlined />
          </div>
        </Dropdown>
      )}
    />
  );
};

export default InlineCategoryManager;
