import React, {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { CreateLearningPathInterface } from "../../../core/interfaces/create-learning-path.interface";
import { FormInstance, useForm } from "antd/lib/form/Form";
import {
  LearningPathStateEnum,
  LearningPathTypeEnum,
} from "../../../core/enums/learning-path-state.enum";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import { Form, Input } from "antd";
import AppConfig from "../../../constants/config/app.config";
import LearningPathTypesComponent from "../learning-path/learning-path-types.component";
import CharacterCounter from "../form-builder/components/character-counter";
import UserGroupSelectFormItem from "../form-builder/components/user-group-select.form-item";
import LearningPathColorPicker from "../form-builder/components/learning-path-color-picker";
import LearningPathModel from "../../../core/models/learning-path/learning-path.model";
import ImageUploadComponent from "../media-upload/image-upload.component";
import MediaModel from "../../../core/models/media/media.model";

export interface CreateLearningFormRef {
  form: FormInstance<CreateLearningPathInterface>;
}

interface Props {
  learningPath?: LearningPathModel;
}

const titleMaxLength = AppConfig.learningPathTitleMaxLength;
const descriptionMaxLength = AppConfig.learningPathDescriptionMaxLength;

const CreateLearningPathForm = forwardRef(
  ({ learningPath }: Props, ref: Ref<CreateLearningFormRef>) => {
    const [t] = useTranslation();
    const [form] = useForm<CreateLearningPathInterface>();
    const [title, setTitle] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [type, setType] = useState<LearningPathTypeEnum>();
    const [userGroups, setUserGroups] = useState<string[]>([]);
    const [color, setColor] = useState("");

    const [media, setMedia] = useState<MediaModel>();
    const [mediaUid, setMediaUid] = useState<string>();

    useImperativeHandle(ref, () => ({ form }));

    useEffect(() => {
      form.setFields([{ name: "title", value: title }]);
    }, [title]);

    useEffect(() => {
      form.setFields([{ name: "description", value: description }]);
    }, [description]);

    useEffect(() => {
      form.setFields([{ name: "userGroups", value: userGroups }]);
    }, [userGroups]);

    useEffect(() => {
      form.setFields([{ name: "learningPathType", value: type }]);
    }, [type]);

    useEffect(() => {
      form.setFields([{ name: "color", value: color }]);
    }, [color]);

    useEffect(() => {
      form.setFields([{ name: "mediaUid", value: mediaUid }]);
    }, [mediaUid]);

    useEffect(() => {
      if (!learningPath) {
        return;
      }

      if (!learningPath.learningPathType) {
        setType(LearningPathTypeEnum.OPEN);
      } else {
        setType(learningPath.learningPathType);
      }

      setTitle(learningPath.title);
      if (learningPath.userGroups) setUserGroups(learningPath.userGroups);
      if (learningPath.description) setDescription(learningPath.description);
      if (learningPath.color) setColor(learningPath.color);
      if (learningPath.media?.length > 0) setMedia(learningPath.media[0]);
      if (learningPath.mediaUids?.length > 0)
        setMediaUid(learningPath.mediaUids[0]);
    }, [learningPath]);

    return (
      <Form form={form} labelCol={{ span: 6 }} labelAlign={"left"}>
        <Form.Item
          name={"title"}
          label={Capitalize(t("form.card.title.label"))}
          rules={getRules("title")}
        >
          <Input
            value={title}
            type={"text"}
            suffix={
              <CharacterCounter
                currentLength={title.length}
                maxLength={titleMaxLength}
              />
            }
            maxLength={titleMaxLength}
            style={{ width: "90%" }}
            placeholder={t("form.placeholders.type_x", {
              item: t("form.card.title.label"),
            })}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          name={"description"}
          label={Capitalize(t("form.card.description.label"))}
          rules={getRules("description")}
        >
          <Input
            value={description}
            type={"text"}
            suffix={
              <CharacterCounter
                currentLength={description.length}
                maxLength={descriptionMaxLength}
              />
            }
            maxLength={descriptionMaxLength}
            style={{ width: "90%" }}
            placeholder={t("form.items.learning-path.placeholder")}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          name={"learningPathType"}
          label={Capitalize(
            t("common.type") + " " + t("containers.learning-path.key"),
          )}
          rules={[
            {
              required: true,
              message: Capitalize(t("translations:errors.required-generic")),
            },
          ]}
        >
          <div style={{ width: "90%" }}>
            <LearningPathTypesComponent
              type={type}
              setType={setType}
              disabled={!!learningPath}
            />
          </div>
        </Form.Item>

        <UserGroupSelectFormItem
          name={"userGroups"}
          initialValue={userGroups}
          onChange={setUserGroups}
          excludeAdminGroups
          disabled={learningPath?.state === LearningPathStateEnum.POSTED}
        />

        <Form.Item
          name={"color"}
          label={Capitalize(t("translations:common.color"))}
        >
          <div style={{ width: "90%" }}>
            <LearningPathColorPicker value={color} onChange={setColor} />
          </div>
        </Form.Item>

        <Form.Item
          name={"mediaUid"}
          label={Capitalize(t("translations:common.image"))}
        >
          <div style={{ width: "90%" }}>
            <ImageUploadComponent
              aspectRatio={1}
              media={media}
              onUpload={(mediaUid) => setMediaUid(mediaUid)}
            />
          </div>
        </Form.Item>
      </Form>
    );

    function getRules(field: string) {
      const maxLength =
        field === "title" ? titleMaxLength : descriptionMaxLength;
      return [
        {
          max: maxLength,
          message: Capitalize(
            t("errors.max-length", {
              field: t(`form.card.${field}.label`),
              amount: maxLength,
            }),
          ),
        },
        {
          required: true,
          message: Capitalize(
            t("errors.required", {
              item: t(`form.card.${field}.label`),
            }),
          ),
        },
      ];
    }
  },
);

CreateLearningPathForm.displayName = "CreateLearningPathForm";

export default CreateLearningPathForm;
