import React, { useEffect, useState } from "react";
import { TableRowSelection } from "antd/lib/table/interface";
import { ColumnsType } from "antd/lib/table";
import { Modal, Space, Table, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import ContextActionIconComponent from "../../components/icons/context-action-icon.component";
import ContainerIconComponent from "../../components/icons/container-icon.component";
import GetColumnSearchProps from "../../../core/utils/get-column-search-props.utils";
import ContentActionComponent from "../../components/content-action/content-action.component";
import UserGroupAction from "../../../core/redux/stores/user-group/user-group.action";
import UserGroupModel from "../../../core/models/user-group/user-group.model";
import colors from "../../styles/colors";
import Moment from "moment";
import { Link } from "react-router-dom";
import UserRoleEnum from "../../../core/enums/user-role.enum";
import { selectCurrentUserRole } from "../../../core/redux/selectors/user/user.selector";
import { selectRequesting } from "../../../core/redux/selectors/requesting/requesting.selector";

interface Props {
  groups: UserGroupModel[];
  onEdit: (data: UserGroupModel) => void;
  onSelectNotification?: (data: UserGroupModel[]) => void;
}

const UserGroupsOverviewScreen = ({
  groups,
  onEdit,
  onSelectNotification,
}: Props) => {
  const { t } = useTranslation();
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>("");
  const [selectedRow, setSelectedRows] = useState<UserGroupModel[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const dispatch = useDispatch();
  const userRole: UserRoleEnum = useSelector(selectCurrentUserRole);
  const isFetchingUserGroups = useSelector((state) =>
    selectRequesting(state, [UserGroupAction.REQUEST_USER_GROUPS]),
  );

  useEffect(() => {
    if (isSubmitting && !isFetchingUserGroups) {
      setIsSubmitting(false);
    }
  }, [isFetchingUserGroups]);

  const columns: ColumnsType<UserGroupModel> = [
    {
      key: "title",
      title: Capitalize(t("form.items.name.label")),
      dataIndex: "title",
      ...GetColumnSearchProps({ dataIndex: "title" }),
      render: function renderText(text) {
        return (
          <Typography.Text ellipsis style={{ width: 140 }}>
            {text}
          </Typography.Text>
        );
      },
    },
    {
      key: "description",
      title: Capitalize(t("form.card.description.label")),
      dataIndex: "description",
      ...GetColumnSearchProps({ dataIndex: "description" }),
      render: function renderText(text) {
        return (
          <Typography.Text ellipsis style={{ width: 220 }}>
            {text ? text : "-"}
          </Typography.Text>
        );
      },
    },
    {
      key: "users",
      title: (
        <Typography.Text ellipsis style={{ width: 120 }}>
          {Capitalize(
            t("common.amount", { item: t("containers.users.key_plural") }),
          )}
        </Typography.Text>
      ),
      dataIndex: "users",
      width: 120,
      sorter: (a, b) => a.users.length - b.users.length,
      render: function renderText(_, data: UserGroupModel) {
        return (
          <Typography.Text>
            {data.users ? data.users.length : "n/a"}
          </Typography.Text>
        );
      },
    },
    {
      key: "createdAt",
      title: (
        <Typography.Text ellipsis style={{ width: 120 }}>
          {Capitalize(t("form.items.created_at.label"))}
        </Typography.Text>
      ),
      dataIndex: "createdAt",
      width: 120,
      sorter: (a, b) => +new Date(a.created_at) - +new Date(b.created_at),
      render: function renderText(_, data: UserGroupModel) {
        return (
          <Typography.Text>
            {Moment(data.created_at).format("DD/MM/YYYY")}
          </Typography.Text>
        );
      },
    },
    {
      dataIndex: "action",
      width: 1,
      render: function showActions(_, data: UserGroupModel) {
        return (
          <Space size={"middle"} style={{ float: "right" }}>
            <Link to={{ pathname: `/user-groups/${data.title as string}` }}>
              <ContentActionComponent
                icon={<ContextActionIconComponent action={"open"} />}
              />
            </Link>

            {userRole !== UserRoleEnum.GROUP_ADMIN && (
              <ContentActionComponent
                icon={
                  <ContextActionIconComponent
                    action={"edit"}
                    style={{ color: "var(--primary-color)" }}
                  />
                }
                onClick={() => {
                  dispatch(UserGroupAction.getUserGroup(data.title));
                  onEdit(data);
                }}
              />
            )}
          </Space>
        );
      },
    },
  ];

  const rowSelection: TableRowSelection<UserGroupModel> = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
    onSelect: (_r, _s, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    selections: [
      {
        key: "notification",
        text: (
          <>
            <ContainerIconComponent
              screen={"notifications"}
              style={{ paddingRight: "1rem" }}
            />
            <Typography.Text>
              {Capitalize(t("common.create")) +
                " " +
                t("containers.notifications.key")}
            </Typography.Text>
          </>
        ),
        onSelect: () => {
          if (onSelectNotification) onSelectNotification(selectedRow);
        },
      },
      {
        key: "delete",
        text: (
          <>
            <ContextActionIconComponent
              action={"delete"}
              style={{ color: colors.secondary, paddingRight: "1rem" }}
            />
            <Typography.Text style={{ color: colors.secondary }}>
              {Capitalize(t("common.delete"))}
            </Typography.Text>
          </>
        ),
        onSelect: () => deleteUserGroupModal(selectedRow),
      },
    ],
  };

  return (
    <Table<UserGroupModel>
      dataSource={groups}
      columns={columns}
      rowKey={(data) => data.title}
      rowSelection={rowSelection}
      pagination={false}
      loading={isSubmitting}
      tableLayout={"fixed"}
      scroll={{ x: true }}
      size={"middle"}
    />
  );

  function deleteUserGroupModal(data: UserGroupModel[]) {
    const groupNames: any = [];
    for (let i = 0; i < data.length; i++) {
      const title =
        data[i] === data[data.length - 1]
          ? data[i].title
          : data[i].title + ", ";
      groupNames.push(title);
    }

    Modal.confirm({
      title: Capitalize(
        t("errors.warnings.delete", { field: t("containers.user-groups.key") }),
      ),
      content: (
        <Typography.Text>
          {Capitalize(t("containers.user-groups.key"))}: {groupNames}
        </Typography.Text>
      ),
      icon: (
        <ContextActionIconComponent
          action={"delete"}
          style={{ color: colors.secondary }}
        />
      ),
      cancelText: Capitalize(t("common.cancel")),
      cancelButtonProps: { type: "text" },
      okText: Capitalize(t("common.delete")),
      onOk() {
        setIsSubmitting(true);
        data.forEach((userGroup: UserGroupModel) => {
          dispatch(UserGroupAction.deleteUserGroup(userGroup.title));
        });
      },
    });
  }
};

export default UserGroupsOverviewScreen;
