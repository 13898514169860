import React from "react";
import { Button, Form } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { grey } from "@ant-design/colors";
import { Capitalize } from "../../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";
import ContextActionIconComponent from "../../icons/context-action-icon.component";
import MultipleChoiceOptionModel from "../../../../core/models/content/card-types/multiple-choice/multiple-choice-option.model";
import QuizOptionModel from "../../../../core/models/content/card-types/quiz/quiz-option.model";
import SliderOptionModel from "../../../../core/models/content/card-types/slider/slider-option.model";
import CreateOptionForm, { CreateOptionInterface } from "./create-option.form";
import ContentActionComponent from "../../content-action/content-action.component";
import colors from "../../../styles/colors";

interface OwnProps {
  options:
    | MultipleChoiceOptionModel[]
    | QuizOptionModel[]
    | SliderOptionModel[];
  maxOptions: boolean;
  minOptions: boolean;
  editOption: (index: number, value: CreateOptionInterface) => void;
  addOption: () => void;
  deleteOption?: () => void;
}

type Props = OwnProps;

const CreateOptionContainerComponent = (props: Props) => {
  const [t] = useTranslation();
  const {
    options,
    maxOptions,
    minOptions,
    addOption,
    deleteOption,
    editOption,
  } = props;

  return (
    <Form.Item
      initialValue={options}
      tooltip={" "}
      rules={[{ required: true }]}
      label={Capitalize(t("form.card.answers.label"))}
    >
      {options.map(
        (
          _option:
            | MultipleChoiceOptionModel
            | QuizOptionModel
            | SliderOptionModel,
          index: number,
        ) => {
          return (
            <div key={index} style={{ display: "flex" }}>
              <div style={{ width: "80%" }}>
                <CreateOptionForm
                  index={index}
                  option={_option}
                  onChangeOption={(value) => editOption(index, value)}
                />
              </div>
              {minOptions && (
                <div style={{ paddingLeft: "1.5rem" }}>
                  <ContentActionComponent
                    icon={
                      <ContextActionIconComponent
                        action={"delete"}
                        style={{ color: colors.secondary }}
                      />
                    }
                    onClick={() => handleOnDeleteOption(index)}
                  />
                </div>
              )}
            </div>
          );
        },
      )}
      <Button
        type={"text"}
        disabled={maxOptions}
        onClick={addOption}
        style={{ color: maxOptions ? grey[0] : colors.primary }}
      >
        <PlusOutlined /> {t("form.card.answers.button")}
      </Button>
    </Form.Item>
  );

  function handleOnDeleteOption(index: number) {
    options.splice(index, 1);

    if (deleteOption) {
      deleteOption();
    }
  }
};

export default CreateOptionContainerComponent;
