import React from "react";
import { Alert, Menu, Modal, Typography } from "antd";
import { LearningPathStateEnum } from "../../../core/enums/learning-path-state.enum";
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  ReconciliationOutlined,
  RocketOutlined,
} from "@ant-design/icons";
import { Capitalize } from "../../../core/utils/helper.utils";
import { ModalNames, useModal } from "../../../core/providers/modal.provider";
import colors from "../../styles/colors";
import ActionButtonComponent from "../actions/action-button.component";
import { useTranslation } from "react-i18next";
import LearningPathModel from "../../../core/models/learning-path/learning-path.model";
import learningPathPublicationValidator from "../../../core/utils/learning-path-publication-validator";
import ContextActionIconComponent from "../icons/context-action-icon.component";
import LearningPathAction from "../../../core/redux/stores/learning-path/learning-path.action";
import { useDispatch } from "react-redux";
import { RoutesEnum } from "../../../constants/routes/app.route";
import { Link, useHistory } from "react-router-dom";
import {
  useDuplicateLearningPath,
  useGetPlaybooks,
} from "../../../core/api/primio/primioComponents";

const LearningPathContextMenu = ({
  learningPath,
}: {
  learningPath: LearningPathModel;
}) => {
  const { openModal } = useModal();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { data: learningPathPlaybooks = [] } = useGetPlaybooks({
    queryParams: {
      "playbook-uids": learningPath.content.map((c) => c.contentUid).join(","),
      excludeMedia: true,
    },
  });

  const { mutateAsync: duplicateLearningPath } = useDuplicateLearningPath({
    onSuccess: () => dispatch(LearningPathAction.getAllLearningPaths()),
  });

  function publishLearningPath(data: LearningPathModel) {
    const validation = learningPathPublicationValidator(
      learningPath,
      learningPathPlaybooks,
    );

    const isReadyToPublish =
      validation.filter((p) => p.type === "error").length === 0;

    Modal.confirm({
      title: Capitalize(t("screens.bulk-publish.title")),
      content: (
        <>
          <Typography.Paragraph>
            {Capitalize(t("containers.learning-path.key"))}: {data.title}
          </Typography.Paragraph>

          {validation.map((item, index) => (
            <Alert
              key={index}
              type={item.type}
              message={item.label}
              description={item.errorMessage}
              style={{ marginTop: index === 0 ? 0 : 12 }}
            />
          ))}
        </>
      ),
      icon: (
        <ContextActionIconComponent
          action={"publish"}
          style={{ color: colors.primary }}
        />
      ),
      okText: Capitalize(t("common.publish")),
      onOk() {
        dispatch(LearningPathAction.postLearningPath(data));
      },
      okButtonProps: {
        disabled: !isReadyToPublish,
      },
      cancelText: Capitalize(t("common.cancel")),
      cancelButtonProps: { type: "text" },
    });
  }

  function revokeLearningPath(data: LearningPathModel) {
    Modal.confirm({
      title: Capitalize(t("errors.warnings.archive")),
      content: (
        <Typography.Text>
          {Capitalize(t("containers.learning-path.key"))}: {data.title}
        </Typography.Text>
      ),
      icon: (
        <ContextActionIconComponent
          action={"delete"}
          style={{ color: colors.secondary }}
        />
      ),
      okText: Capitalize(t("common.archive")),
      onOk() {
        switch (data.state) {
          case LearningPathStateEnum.CREATED:
          case LearningPathStateEnum.REVOKED:
            dispatch(LearningPathAction.deleteLearningPath(data));
            break;
          case LearningPathStateEnum.POSTED:
            dispatch(LearningPathAction.revokeLearningPath(data));
            break;
        }

        history.replace(RoutesEnum.LEARNING_PATH);
      },
      cancelText: Capitalize(t("common.cancel")),
      cancelButtonProps: { type: "text" },
    });
  }

  return (
    <ActionButtonComponent
      overlay={
        <Menu>
          {learningPath.state === LearningPathStateEnum.CREATED && (
            <Menu.Item
              key={"0"}
              icon={<RocketOutlined />}
              onClick={() => publishLearningPath(learningPath)}
            >
              {Capitalize(t("common.publish"))}
            </Menu.Item>
          )}
          <Menu.Item
            key={"1"}
            icon={<EditOutlined />}
            onClick={() =>
              openModal(ModalNames.CREATE_LEARNING_PATH, {
                learningPath,
              })
            }
          >
            {Capitalize(t("common.edit"))}
          </Menu.Item>
          <Menu.Item
            key={"2"}
            icon={<CopyOutlined />}
            onClick={() => {
              duplicateLearningPath({
                pathParams: { learningPathUid: learningPath.learningPathUid },
              });
              history.replace(RoutesEnum.LEARNING_PATH);
            }}
          >
            {Capitalize(t("common.duplicate"))}
          </Menu.Item>
          <Menu.Item key={"3"} icon={<ReconciliationOutlined />}>
            <Link
              to={RoutesEnum.LEARNING_PATH_DETAIL_REPORT.replace(
                ":uid",
                learningPath.learningPathUid,
              )}
            >
              {Capitalize(t("common.report"))}
            </Link>
          </Menu.Item>

          <Menu.Item
            key={"4"}
            icon={<DeleteOutlined />}
            style={{ color: colors.secondary }}
            onClick={() => revokeLearningPath(learningPath)}
          >
            {Capitalize(t("common.delete"))}
          </Menu.Item>
        </Menu>
      }
    />
  );
};

export default LearningPathContextMenu;
