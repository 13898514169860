import React, { useState } from "react";
import { CreateCardInterface } from "../../../../core/models/create-content/create-base-card.model";
import { useDispatch } from "react-redux";
import { Form } from "antd";
import { ContentButtonStatesEnum } from "../../../../core/enums/content-button-states";
import { ContentStatesEnum } from "../../../../core/enums/content-states.enum";
import { CardTypesEnum } from "../../../../core/enums/card-types.enum";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../../core/utils/helper.utils";
import TranslateDefaults from "../../../../core/utils/translate-defaults.utils";
import ContentAction from "../../../../core/redux/stores/content/content.action";
import ContentTypesEnum from "../../../../core/enums/content-types.enum";
import CreateCardBaseForm from "../base-card/create-card-base.form";
import CreateQuestionCardModel, {
  CreateQuestionCardResponse,
} from "../../../../core/models/create-content/card-types/create-question-card.model";
import QuestionCardModel from "../../../../core/models/content/card-types/question/question-card.model";
import FormQuestionField from "../../form-builder/components/form-question.field";
import CharacterCounter from "../../form-builder/components/character-counter";
import AppConfig from "../../../../constants/config/app.config";
import TextArea from "antd/lib/input/TextArea";
import styles from "../card-types.module.css";
import { MediaTypesEnum } from "../../../../core/enums/media-providers.enum";
import MediaModel from "../../../../core/models/media/media.model";
import MediaUploadSelectComponent from "../../media-upload/media-upload-select.component";

type CreateProps = {
  cardContent: undefined;
  collectionUid: string;
};

type EditProps = {
  cardContent: QuestionCardModel;
};

type Props = CreateProps | EditProps;

const CreateQuestionCardForm = (props: Props) => {
  const [t] = useTranslation();
  const { cardContent } = props;
  const [question, setQuestion] = useState<string>(
    cardContent ? TranslateDefaults(cardContent.data.question) : "",
  );
  const [explanation, setExplanation] = useState<string>(
    cardContent ? TranslateDefaults(cardContent.data?.explanation ?? "") : "",
  );
  const [media, setMedia] = useState<MediaModel | undefined>(
    cardContent?.media ? cardContent.media[0] : undefined,
  );
  const dispatch = useDispatch();

  const isCreating = props.cardContent === undefined;
  const isEditing = !isCreating;

  const explanationMaxLength = AppConfig.formExplanationMaxLength;

  function handleOnFinish(
    data: CreateCardInterface,
    isPressedButton?: ContentButtonStatesEnum,
  ) {
    const createQuestionCard: CreateQuestionCardResponse = {
      ...data,
      title: question,
      description:
        media && media.mediaType !== MediaTypesEnum.NO_MEDIA
          ? ""
          : data.description,
      collectionUid: props.cardContent
        ? props.cardContent.collectionUid
        : props.collectionUid,
      cardType: CardTypesEnum.QUESTION,
      mediaUids: media?.mediaUid ? [media.mediaUid] : data.mediaUids,
      data: {
        question,
        explanation,
      },
    };

    const CreateQuestionCard = new CreateQuestionCardModel(createQuestionCard);

    if (isCreating) {
      dispatch(
        ContentAction.createContent(ContentTypesEnum.CARD, CreateQuestionCard),
      );
    }

    if (isEditing && cardContent) {
      if (
        isPressedButton &&
        cardContent.contentState === ContentStatesEnum.PUBLISHED
      ) {
        switch (isPressedButton) {
          case ContentButtonStatesEnum.CREATE:
            dispatch(
              ContentAction.updateContent(
                ContentTypesEnum.CARD,
                cardContent,
                CreateQuestionCard,
              ),
            );
            break;
          case ContentButtonStatesEnum.UPDATE:
            cardContent.version++;
            dispatch(
              ContentAction.updateAndPublishCard(
                cardContent,
                CreateQuestionCard,
              ),
            );
            break;
        }
      } else {
        dispatch(
          ContentAction.updateContent(
            ContentTypesEnum.CARD,
            cardContent,
            CreateQuestionCard,
          ),
        );
      }
    }
  }

  return (
    <CreateCardBaseForm
      cardContent={cardContent}
      disabledButton={!question}
      hideFields={true}
      onSubmit={handleOnFinish}
    >
      <>
        <MediaUploadSelectComponent
          media={media}
          onChange={setMedia}
          contentType={ContentTypesEnum.CARD}
        />

        <FormQuestionField question={question} setQuestion={setQuestion} />

        <Form.Item
          initialValue={explanation}
          name={"explanation"}
          label={Capitalize(t("form.card.explanation.label"))}
        >
          <div>
            <TextArea
              value={explanation}
              rows={4}
              style={{ width: "80%" }}
              maxLength={explanationMaxLength}
              placeholder={t("form.card.explanation.placeholder")}
              onChange={(e) => setExplanation(e.target.value)}
            />
            <CharacterCounter
              currentLength={explanation?.length ?? 0}
              maxLength={explanationMaxLength}
              className={styles.character_counter}
            />
          </div>
        </Form.Item>
      </>
    </CreateCardBaseForm>
  );
};

export default CreateQuestionCardForm;
