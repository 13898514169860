import React from "react";
import Table from "antd/lib/table";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal, Space, Tag, Typography } from "antd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { Capitalize } from "../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";
import DraggableTableRowComponent from "../../components/draggable/draggable-table-row.component";
import PlaybookModel from "../../../core/models/content/playbook.model";
import colors from "../../styles/colors";
import ContextActionIconComponent from "../../components/icons/context-action-icon.component";
import ContentActionComponent from "../../components/content-action/content-action.component";
import { useApiKeyClients } from "../../../core/hooks/api-key/api-key.hook";
import { Category } from "../../../core/api/primio/primioSchemas";
import { useUpdateCategory } from "../../../core/api/primio/primioComponents";
import { useQueryClient } from "@tanstack/react-query";

interface OwnProps {
  category: Category;
}

type Props = OwnProps;

const CategoriesDetailOverviewScreen = ({ category }: Props) => {
  const { t } = useTranslation();
  const { data: apiKeyClients = [] } = useApiKeyClients();
  const queryClient = useQueryClient();
  const { mutateAsync: updateCategory, isLoading } = useUpdateCategory({
    onSuccess: () =>
      queryClient.invalidateQueries(["categories", category.categoryUid]),
  });

  const moveRow = (dragIndex: number, hoverIndex: number) => {
    const items = [...category.playbooks];
    const [newOrder] = items.splice(dragIndex, 1);
    items.splice(hoverIndex, 0, newOrder);

    return updateCategory({
      pathParams: {
        categoryUid: category.categoryUid,
      },
      body: {
        playbookUids: items.map((playbook) => playbook.playbookUid),
      },
    });
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Table
        // @ts-ignore
        dataSource={category.playbooks || []}
        columns={[
          {
            key: "title",
            title: Capitalize(t("form.card.title.label")),
            dataIndex: "title",
            render: function renderText(text) {
              return <Typography.Text ellipsis>{text}</Typography.Text>;
            },
          },
          {
            key: "apiKeyClientUid",
            title: t(
              "translations:screens.shared-content.statistics-block.label",
            ),
            dataIndex: "apiKeyClientUid",
            render: function renderSharedContent(_, data) {
              const apiKeyClient = apiKeyClients.find(
                (apiKeyClient) =>
                  apiKeyClient.apiKeyClientUid === data.apiKeyClientUid,
              );

              if (!apiKeyClient) {
                return;
              }

              return <p>{Capitalize(apiKeyClient.server)}</p>;
            },
          },
          {
            key: "contentState",
            title: Capitalize(t("content-state.state")),
            dataIndex: "contentState",
            width: 1,
            sorter: (a: PlaybookModel, b: PlaybookModel) =>
              +new Date(a.contentState) - +new Date(b.contentState),
            render: function renderText(_, data: PlaybookModel) {
              return (
                <Tag>
                  {Capitalize(
                    t(`translations:content-state.${data.contentState}`),
                  )}
                </Tag>
              );
            },
          },
          {
            key: "action",
            width: 1,
            render: function renderActions(_, data) {
              return (
                <Space
                  size={"middle"}
                  style={{ float: "right", marginRight: 16 }}
                >
                  <ContentActionComponent
                    icon={
                      <ContextActionIconComponent
                        action={"delete"}
                        style={{ color: "var(--secondary800-color)" }}
                      />
                    }
                    onClick={() => handleDeletePlaybookFromCategory(data)}
                  />
                </Space>
              );
            },
          },
        ]}
        onRow={(_, index) => {
          const attr = { index, moveRow };
          return attr as React.HTMLAttributes<any>;
        }}
        rowKey={(data) => data.playbookUid}
        pagination={false}
        tableLayout={"fixed"}
        loading={isLoading}
        scroll={{ x: true }}
        size={"middle"}
        components={{ body: { row: DraggableTableRowComponent } }}
      />
    </DndProvider>
  );

  function handleDeletePlaybookFromCategory(playbook: PlaybookModel) {
    Modal.confirm({
      title: Capitalize(
        t("errors.warnings.delete-from", {
          field: t("containers.modules.key"),
          item: t("containers.categories.key"),
        }),
      ),
      content: (
        <Typography.Text>
          {Capitalize(t("containers.modules.key"))}: {playbook.title}
        </Typography.Text>
      ),
      icon: <ExclamationCircleOutlined style={{ color: colors.secondary }} />,
      cancelText: Capitalize(t("common.cancel")),
      cancelButtonProps: { type: "text" },
      okText: Capitalize(t("common.delete")),
      async onOk() {
        await handleRemovePlaybook(playbook);
      },
    });
  }

  async function handleRemovePlaybook(playbook: PlaybookModel) {
    if (!category) {
      return;
    }

    const playbookUids = category.playbooks
      .map((playbook) => playbook.playbookUid)
      .filter((playbookUid) => playbookUid !== playbook.playbookUid);

    await updateCategory({
      pathParams: {
        categoryUid: category.categoryUid,
      },
      body: {
        playbookUids,
      },
    });
  }
};

export default CategoriesDetailOverviewScreen;
