import React, { useState } from "react";
import { TableRowSelection } from "antd/lib/table/interface";
import { ColumnsType } from "antd/lib/table";
import { Menu, Space, Table, Typography } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { selectRequesting } from "../../../core/redux/selectors/requesting/requesting.selector";
import { useSelector } from "react-redux";
import { Capitalize } from "../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";
import GetColumnSearchProps from "../../../core/utils/get-column-search-props.utils";
import ContentActionComponent from "../../components/content-action/content-action.component";
import ContextActionIconComponent from "../../components/icons/context-action-icon.component";
import ActionButtonComponent from "../../components/actions/action-button.component";
import InvitationAction from "../../../core/redux/stores/invitation/invitation.action";
import InvitationModel from "../../../core/models/invitation/invitation.model";
import Moment from "moment";
import UserRoleEnum from "../../../core/enums/user-role.enum";
import { selectCurrentUserRole } from "../../../core/redux/selectors/user/user.selector";
import { useGetUserGroups } from "../../../core/api/primio/primioComponents";
import { GroupTags } from "../../components/group-tag";

interface InvitationsOverviewProps {
  invitations: InvitationModel[];
  onSend?: (data: InvitationModel[]) => void;
  onSendAll?: (data: InvitationModel[]) => void;
  onDelete?: (data: InvitationModel) => void;
}

const PendingInvitationsScreen = (props: InvitationsOverviewProps) => {
  const { t } = useTranslation();
  const { invitations, onSend, onSendAll, onDelete } = props;
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>("");
  const [selectedRows, setSelectedRows] = useState<InvitationModel[]>([]);
  const currentUserRole: UserRoleEnum | undefined = useSelector(
    selectCurrentUserRole,
  );

  const requesting = useSelector((state) =>
    selectRequesting(state, [
      InvitationAction.REQUEST_DELETE_INVITATION,
      InvitationAction.REQUEST_MARK_INVITATIONS_AS_SENT,
    ]),
  );

  const NO_GROUPS = Capitalize(t("translations:common.no-groups"));
  const { data: userGroups = [] } = useGetUserGroups(
    {},
    {
      select: (data) => {
        const response = data.map((item) => item.groupType.GroupName);
        response.unshift(NO_GROUPS);
        return response;
      },
    },
  );

  let columns: ColumnsType<InvitationModel> = [
    {
      key: "name",
      title: Capitalize(t("form.items.name.label")),
      dataIndex: "name",
      ...GetColumnSearchProps({ dataIndex: "name" }),
    },
    {
      key: "email",
      title: Capitalize(t("form.items.email.label")),
      dataIndex: "email",
      ...GetColumnSearchProps({ dataIndex: "email" }),
    },
    {
      key: "invitationCode",
      title: Capitalize(t("screens.invitation.code")),
      dataIndex: "invitationCode",
      ...GetColumnSearchProps({ dataIndex: "invitationCode" }),
    },
    {
      key: "userGroupAcl",
      title: Capitalize(t("containers.user-groups.key_plural")),
      width: 250,
      filters: userGroups.map((group) => ({
        text: group,
        value: group,
      })),
      onFilter: (value, record) =>
        value === NO_GROUPS
          ? record.userGroups.length === 0
          : record.userGroups.includes(value as string),
      render: (_, data) => <GroupTags groupNames={data.userGroups ?? []} />,
    },
    {
      key: "send_at",
      title: (
        <Typography.Text ellipsis style={{ width: 120 }}>
          {Capitalize(t("common.invited-at"))}
        </Typography.Text>
      ),
      dataIndex: "sendAt",
      sorter: (a, b) => {
        if (!a.sentAt || !b.sentAt) {
          return 0;
        }
        return +new Date(a.sentAt) - +new Date(b.sentAt);
      },
      defaultSortOrder: "descend",
      render: function renderText(_, data) {
        if (!data.sentAt) {
          return <Typography.Text>-</Typography.Text>;
        }
        return (
          <Typography.Text>
            {Moment(data.sentAt).format("DD/MM/YYYY (LT)")}
          </Typography.Text>
        );
      },
    },
    {
      key: "action",
      width: 1,
      render: function showActions(_, data) {
        return (
          <Space size={"middle"} style={{ float: "right" }}>
            <ContentActionComponent
              icon={
                <ContextActionIconComponent
                  action={"delete"}
                  style={{ color: "var(--secondary800-color)" }}
                />
              }
              onClick={() => {
                if (onDelete) onDelete(data);
              }}
            />
          </Space>
        );
      },
    },
  ];

  if (
    currentUserRole !== UserRoleEnum.SYS_ADMIN &&
    currentUserRole !== UserRoleEnum.PRIMIO_SUPPORT
  ) {
    columns = columns.filter((item) => item.key !== "invitationCode");
  }

  const rowSelection: TableRowSelection<InvitationModel> = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
    onSelect: (_r, _s, selectedRows) => {
      setSelectedRows(selectedRows);
    },
  };

  const menu = (
    <Menu>
      <Menu.Item
        key={"0"}
        icon={<MailOutlined />}
        disabled={selectedRows.length === 0}
        onClick={() => {
          if (onSend) onSend(selectedRows);
        }}
      >
        {Capitalize(
          t("screens.invitation.resend-to", { value: selectedRows.length }),
        )}
      </Menu.Item>
      <Menu.Item
        key={"1"}
        icon={<MailOutlined />}
        onClick={() => {
          if (onSendAll) onSendAll(invitations);
        }}
      >
        {Capitalize(t("screens.invitation.resend-to-all")) +
          ` (${invitations.length})`}
      </Menu.Item>
    </Menu>
  );

  return (
    <div style={{ marginTop: "-2rem" }}>
      <ActionButtonComponent selection={selectedRowKeys} overlay={menu} />
      <Table<InvitationModel>
        dataSource={invitations}
        columns={columns}
        rowKey={(data) => data.invitationUid}
        pagination={false}
        rowSelection={rowSelection}
        loading={requesting}
        size={"small"}
      />
    </div>
  );
};
export default PendingInvitationsScreen;
