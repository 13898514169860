import React from "react";
import { RoutesEnum } from "../../../constants/routes/app.route";
import { Dropdown, Typography } from "antd";
import { LearningPath } from "../../../core/api/primio/primioSchemas";
import { useHistory } from "react-router-dom";
import styles from "../inline-attribute-manager/inline-attribute-manager.module.css";
import { interactiveText } from "../../styles/typography";

const InlinePlaybookLearningPathManager = ({
  learningPaths,
}: {
  learningPaths: LearningPath[];
}) => {
  const history = useHistory();
  return (
    <Dropdown
      trigger={["click"]}
      dropdownRender={() => (
        <div
          className="ant-dropdown-menu"
          style={{
            width: 250,
            display: "flex",
            flexDirection: "column",
            padding: 0,
          }}
        >
          <div className={styles.attribute_manager}>
            <ul style={{ paddingTop: 4, paddingBottom: 4 }}>
              {learningPaths.map((learningPath) => (
                <li
                  key={learningPath.learningPathUid}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      cursor: "pointer",
                      overflow: "hidden",
                    }}
                    onClick={() => {
                      const route =
                        learningPath.learningPathType === null
                          ? (RoutesEnum.LEARNING_PATH_DETAIL as string)
                          : (RoutesEnum.LEARNING_PATH_DETAIL_LEGACY as string);
                      history.push(
                        route.replace(":uid", learningPath.learningPathUid),
                      );
                    }}
                  >
                    <Typography.Text style={interactiveText}>
                      {learningPath.title}
                    </Typography.Text>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    >
      <div style={{ cursor: "pointer" }}>
        {learningPaths.map((learningPath) => (
          <div
            style={{ display: "inline-block" }}
            key={learningPath.learningPathUid}
          >
            <Typography.Text style={interactiveText}>
              {learningPath.title}
            </Typography.Text>
          </div>
        ))}
      </div>
    </Dropdown>
  );
};

export default InlinePlaybookLearningPathManager;
