import React, { useCallback, useState } from "react";
import { useLearningPath } from "../../../core/providers/learning-path.provider";
import PlaybooksSelectComponent, {
  SelectedPlaybookUidsType,
} from "../forms/select/playbooks-select.component";
import { Button } from "antd";
import { Playbook } from "../../../core/api/primio/primioSchemas";

const LearningPathAddContent = () => {
  const [showAddContentForm, setShowAddContentForm] = useState(false);
  const { addContent, learningPath } = useLearningPath();

  const filterPlaybooks = useCallback(
    (playbook: Playbook) => {
      // Filter out deleted and draft playbooks
      return playbook.contentState === "PUBLISHED";
    },
    [learningPath],
  );

  async function handleSubmit(value: SelectedPlaybookUidsType[]) {
    await addContent(value.map((p) => p.playbookUid));

    setShowAddContentForm(false);
  }

  if (learningPath.state === "POSTED") {
    return null;
  }

  return (
    <>
      <Button type="primary" onClick={() => setShowAddContentForm(true)}>
        Add content
      </Button>
      {showAddContentForm && (
        <div
          style={{ pointerEvents: "none", position: "absolute", opacity: 0 }}
        >
          <PlaybooksSelectComponent
            playbookUids={[]}
            onChange={handleSubmit}
            filter={filterPlaybooks}
            openDrawerImmediately
            onClose={() => setShowAddContentForm(false)}
          />
        </div>
      )}
    </>
  );
};

export default LearningPathAddContent;
