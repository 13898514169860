import React from "react";
import { ContentStatesEnum } from "../../../core/enums/content-states.enum";
import { ExpandOutlined, RocketOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";

const ContentStateIcon = ({
  contentState,
}: {
  contentState: ContentStatesEnum;
}) => {
  const { t } = useTranslation();
  return (
    <Tooltip
      title={Capitalize(t(`translations:content-state.${contentState}`))}
    >
      {contentState === ContentStatesEnum.PUBLISHED && <RocketOutlined />}
      {contentState === ContentStatesEnum.DRAFT && <ExpandOutlined />}
    </Tooltip>
  );
};

export default ContentStateIcon;
