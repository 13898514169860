import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ColumnsType } from "antd/lib/table";
import { Button, message, Modal, Space, Table, Typography } from "antd";
import { selectRequesting } from "../../../core/redux/selectors/requesting/requesting.selector";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import ContextActionIconComponent from "../icons/context-action-icon.component";
import GetColumnSearchProps from "../../../core/utils/get-column-search-props.utils";
import PaginationUtils from "../../../core/utils/pagination.utils";
import UserGroupAction from "../../../core/redux/stores/user-group/user-group.action";
import UserGroupModel from "../../../core/models/user-group/user-group.model";
import UserModel from "../../../core/models/user/user.model";
import Moment from "moment";
import colors from "../../styles/colors";
import { selectErrorText } from "../../../core/redux/selectors/error/error.selector";
import ErrorAction from "../../../core/redux/stores/error/error.action";
import { Link } from "react-router-dom";
import UserGroupService from "../../../core/services/user-group.service";

interface OwnProps {
  group: UserGroupModel;
}

type Props = OwnProps;

const UserGroupUsersComponent = (props: Props) => {
  const { t } = useTranslation();
  const { group } = props;
  const dispatch = useDispatch();
  const requesting = useSelector((state) =>
    selectRequesting(state, [UserGroupAction.REQUEST_USER_GROUP]),
  );
  const isRemovingUser = useSelector((state) =>
    selectRequesting(state, [UserGroupAction.REQUEST_REMOVE_USERS_FROM_GROUP]),
  );
  const removeUserError = useSelector((state) =>
    selectErrorText(state, [
      UserGroupAction.REQUEST_REMOVE_USERS_FROM_GROUP_FINISHED,
    ]),
  );

  const { handleTableChange, loading, pagination } = PaginationUtils({
    dispatchAction: UserGroupAction.getUserGroup(group.title),
    pagination: { current: 1, pageSize: 15 },
  });

  useEffect(() => {
    if (!removeUserError || isRemovingUser) {
      return;
    }

    message.error(removeUserError).then(() => {
      dispatch(ErrorAction.clearAll());
    });
  }, [removeUserError, isRemovingUser]);

  const columns: ColumnsType<UserModel> = [
    {
      key: "name",
      title: Capitalize(t("form.items.name.label")),
      dataIndex: "name",
      width: "30%",
      ...GetColumnSearchProps({ dataIndex: "name", enableSearch: false }),
      render: function render(_, user) {
        return (
          <Link to={{ pathname: `/users/${user.username as string}` }}>
            <span>{user.name}</span>
          </Link>
        );
      },
    },
    {
      key: "email",
      title: Capitalize(t("form.items.email.label")),
      dataIndex: "email",
      width: "30%",
      ...GetColumnSearchProps({ dataIndex: "email", enableSearch: false }),
    },
    {
      key: "last_active_at",
      title: Capitalize(t("form.items.last_active_at.label")),
      dataIndex: "last_active_at",
      width: "30%",
      sorter: (a, b) =>
        Moment(a.last_active_at).unix() - Moment(b.last_active_at).unix(),
      render: (lastActiveAt) => {
        return lastActiveAt
          ? Moment(lastActiveAt).format("ddd. D MMM yyyy (LT)")
          : "-";
      },
    },
    {
      key: "action",
      width: 1,
      render: function showActions(_, data: UserModel) {
        return (
          <Space size={"middle"} style={{ float: "right" }}>
            <Button
              size={"small"}
              type={"text"}
              icon={
                <ContextActionIconComponent
                  action={"delete"}
                  style={{ color: colors.secondary }}
                />
              }
              onClick={() => deleteUserFromGroupModal(data)}
            />
          </Space>
        );
      },
    },
  ];

  return (
    <Table<UserModel>
      dataSource={group.users}
      columns={columns}
      rowKey={(data) => data.username}
      pagination={pagination}
      onChange={handleTableChange}
      loading={loading || requesting}
      tableLayout={"fixed"}
      scroll={{ x: true }}
      size={"small"}
    />
  );

  function deleteUserFromGroupModal(data: UserModel) {
    Modal.confirm({
      title: Capitalize(
        t("errors.warnings.delete-from", {
          field: t("containers.users.key"),
          item: group.title,
        }),
      ),
      content: (
        <Typography.Text>
          {Capitalize(t("containers.users.key"))}: {data.username}
        </Typography.Text>
      ),
      cancelText: Capitalize(t("common.cancel")),
      okText: Capitalize(t("common.delete")),
      onOk() {
        const userGroupService = new UserGroupService(dispatch);
        userGroupService.removeUsersFromUserGroup(group.title, [data.username]);
      },
    });
  }
};

export default UserGroupUsersComponent;
