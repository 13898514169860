import React, { useEffect, useState } from "react";
import {
  useCreateTag,
  useDeleteTag,
  useGetTags,
  useUpdateTag,
} from "../../../core/api/primio/primioComponents";
import {
  Tag as TagSchema,
  TagWithMinimalPlaybooks,
} from "../../../core/api/primio/primioSchemas";
import InlineAttributeManager from "../inline-attribute-manager/inline-attribute-manager";
import { useQueryClient } from "@tanstack/react-query";
import { tagColors } from "../../../constants";
import { Divider, Dropdown, Input, Menu, Modal, Tag, Typography } from "antd";
import { DeleteOutlined, EllipsisOutlined } from "@ant-design/icons";
import { Capitalize } from "../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";

const sortTag = (a: TagSchema, b: TagSchema) => a.name.localeCompare(b.name);

const InlinePlaybookTagManager = (props: {
  tags: TagSchema[];
  playbookUid: string;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();
  const [tags, setTags] = React.useState<TagSchema[]>([]);
  const { data: allTags = [] } = useGetTags({});

  const qc = useQueryClient();
  const { mutateAsync: createTag } = useCreateTag();
  const { mutateAsync: updateTag } = useUpdateTag();

  useEffect(() => {
    setTags(props.tags);
  }, [props.tags]);

  const handleAddTagToPlaybook = async (tag: TagSchema) => {
    if (tags.find((t) => t.tagUid === tag.tagUid)) {
      return;
    }

    // optimistically add tag
    setTags([...tags, tag]);

    const tagDetail: TagWithMinimalPlaybooks | undefined = allTags.find(
      (t) => t.tagUid === tag.tagUid,
    );
    if (!tagDetail) return;
    const playbookUids = tagDetail.playbooks.map((p) => p.playbookUid);

    await updateTag({
      pathParams: {
        tagUid: tag.tagUid,
      },
      body: {
        playbookUids: [...playbookUids, props.playbookUid],
      },
    });
    await qc.invalidateQueries({
      queryKey: ["v2", "playbooks"],
    });
    await qc.invalidateQueries({
      queryKey: ["tags"],
    });
  };

  const handleRemoveTagFromPlaybook = async (tag: TagSchema) => {
    // optimistically remove tag
    setTags(tags.filter((c) => c.tagUid !== tag.tagUid));

    const tagDetail: TagWithMinimalPlaybooks | undefined = allTags.find(
      (t) => t.tagUid === tag.tagUid,
    );
    if (!tagDetail) return;
    const playbookUids = tagDetail.playbooks
      .map((playbook) => playbook.playbookUid)
      .filter((playbookUid) => playbookUid !== props.playbookUid);

    await updateTag({
      pathParams: {
        tagUid: tag.tagUid,
      },
      body: {
        playbookUids,
      },
    });
    await qc.invalidateQueries({
      queryKey: ["v2", "playbooks"],
    });
    await qc.invalidateQueries({
      queryKey: ["tags"],
    });
  };

  const handleCreateTag = async (name: string) => {
    await createTag({
      body: {
        name,
        playbookUids: [props.playbookUid],
        color: tagColors[0],
      },
    });
    await qc.invalidateQueries({
      queryKey: ["v2", "playbooks"],
    });
    await qc.invalidateQueries({
      queryKey: ["tags"],
    });
  };

  return (
    <InlineAttributeManager<TagSchema>
      data={tags.sort(sortTag)}
      allData={
        allTags
          ? allTags
              .map<TagSchema>((tag) => ({
                tagUid: tag.tagUid,
                name: tag.name,
                color: tag.color,
              }))
              .sort(sortTag)
          : []
      }
      renderItem={(tag) => <Tag color={tag.color}>{tag.name}</Tag>}
      onCreate={handleCreateTag}
      onAdd={handleAddTagToPlaybook}
      onRemove={handleRemoveTagFromPlaybook}
      keyExtractor={(tag) => tag.tagUid}
      disabled={props.disabled}
      label={Capitalize(
        t("translations:common-x.choose-or-create", {
          item: t("translations:common.tag"),
        }),
      )}
      searchKey={"name"}
      renderItemExtra={(tag) => (
        <Dropdown
          arrow
          trigger={["click"]}
          dropdownRender={() => (
            <div className="ant-dropdown-menu" style={{ padding: 0 }}>
              <TagContextMenu tag={tag} />
            </div>
          )}
        >
          <div style={{ cursor: "pointer" }}>
            <EllipsisOutlined />
          </div>
        </Dropdown>
      )}
    />
  );
};

const TagContextMenu = ({ tag }: { tag: TagSchema }) => {
  const { t } = useTranslation();
  const qc = useQueryClient();
  const { mutateAsync: updateTag } = useUpdateTag();
  const { mutateAsync: deleteTag } = useDeleteTag();
  const [tagName, setTagName] = useState(tag.name);

  const handleChangeName = async (tag: TagSchema, name: string) => {
    const trimmedName = name.trim();
    if (tag.name === trimmedName) {
      return;
    }
    await updateTag({
      pathParams: {
        tagUid: tag.tagUid,
      },
      body: {
        name: trimmedName,
      },
    });
    await qc.invalidateQueries(["v2", "playbooks"]);
    await qc.invalidateQueries(["tags"]);
  };

  const handleChangeColor = async (tag: TagSchema, color: string) => {
    await updateTag({
      pathParams: {
        tagUid: tag.tagUid,
      },
      body: {
        color,
      },
    });
    await qc.invalidateQueries(["v2", "playbooks"]);
    await qc.invalidateQueries(["tags"]);
  };

  const handleDeleteTag = (tag: TagSchema) => {
    Modal.confirm({
      title: Capitalize(
        t("translations:errors.warnings.check-delete", {
          item: t("translations:common.tag"),
        }),
      ),
      cancelText: Capitalize(t("common.cancel")),
      okText: Capitalize(t("common.delete")),
      async onOk() {
        await deleteTag({
          pathParams: {
            tagUid: tag.tagUid,
          },
        });
        await qc.invalidateQueries(["v2", "playbooks"]);
        await qc.invalidateQueries(["tags"]);
      },
    });
  };

  const handleOnClick = (e, tag: TagSchema) => {
    if (e.key === "delete") {
      handleDeleteTag(tag);
    }
  };
  return (
    <div>
      <Input
        value={tagName}
        style={{
          border: "none",
          borderRadius: 0,
        }}
        onChange={(e) => setTagName(e.target.value)}
        onPressEnter={() => handleChangeName(tag, tagName)}
      />
      <Divider style={{ margin: 0 }} />

      <div style={{ padding: "0 4px" }}>
        <Typography.Text style={{ fontSize: 12 }}>Select Color</Typography.Text>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto auto auto auto",
            flexDirection: "row",
          }}
        >
          {tagColors.map((color) => (
            <div
              key={color}
              style={{
                borderWidth: 2,
                borderColor: tag.color === color ? color : "transparent",
                borderStyle: "solid",
                borderRadius: 999,
                cursor: "pointer",
                margin: 4,
                padding: 1,
                height: 24,
                width: 24,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => handleChangeColor(tag, color)}
            >
              <div
                style={{
                  borderRadius: 999,
                  height: 16,
                  width: 16,
                  aspectRatio: 1,
                  background: color,
                }}
              />
            </div>
          ))}
        </div>
      </div>

      <Divider style={{ margin: 0 }} />
      <Menu
        mode={"vertical"}
        onClick={(e) => handleOnClick(e, tag)}
        style={{
          boxShadow: "none",
        }}
        items={[
          {
            key: "delete",
            label: Capitalize(t("common.delete")),
            icon: <DeleteOutlined />,
            danger: true,
          },
        ]}
      />
    </div>
  );
};

export default InlinePlaybookTagManager;
