import NewPasswordChallengeCallback from "../types/new-password-challenge-callback.type";
import ConfirmPasswordCallbackType from "../types/confirm-password-callback.type";
import ForgetPasswordCallbackType from "../types/forget-password-callback.type";
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
  IAuthenticationCallback,
} from "amazon-cognito-identity-js";
import * as AWS from "aws-sdk/global";
import {
  COGNITO_CLIENT_ID,
  COGNITO_IDENTITY_POOL_ID,
  COGNITO_REGION,
  COGNITO_USER_POOL_ID,
} from "../../constants/config/env.config";
import { fetchGetAuthenticatedUser } from "../api/primio/primioComponents";
import EntitlementsService from "./entitlements.service";

const userPool = new CognitoUserPool({
  UserPoolId: COGNITO_USER_POOL_ID,
  ClientId: COGNITO_CLIENT_ID,
});

let cognitoUser: CognitoUser | undefined = undefined;

class CognitoService {
  /**
   * Wrapper for Cognito's authenticateUser
   * @param email
   * @param password
   * @param callbacks
   */
  authenticateUser(
    email: string,
    password: string,
    callbacks: Partial<IAuthenticationCallback>,
  ) {
    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    cognitoUser = new CognitoUser({ Username: email, Pool: userPool });
    cognitoUser.authenticateUser(authenticationDetails, {
      ...callbacks,
      onSuccess: (
        session: CognitoUserSession,
        userConfirmationNecessary?: boolean,
      ) => {
        const region = COGNITO_REGION,
          UserPoolId = COGNITO_USER_POOL_ID;

        AWS.config.region = region;

        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: COGNITO_IDENTITY_POOL_ID,
          Logins: {
            [`cognito-idp.${region}.amazonaws.com/${UserPoolId}`]: session
              .getIdToken()
              .getJwtToken(),
          },
        });

        const token = session.getAccessToken().getJwtToken();

        // fetch user
        // check if user has correct entitlements to access CMS
        fetchGetAuthenticatedUser({
          headers: { authorization: `Bearer ${token}` },
        })
          .then((user) => {
            const accessGranted = new EntitlementsService().isEntitled(user, {
              subject: "content",
              scope: 2,
              permission: 1,
            });

            if (accessGranted) {
              callbacks.onSuccess?.(session, userConfirmationNecessary);
            } else {
              callbacks.onFailure?.({ code: "UserNonAdminException" });
              this.signOut();
            }
          })
          .catch((err) => {
            callbacks.onFailure?.({ code: "UserNonAdminException" });
            this.signOut();
            throw new Error(err);
          });
      },
      onFailure: (err) => callbacks.onFailure?.(err),
    });
  }

  /**
   * Wrapper for Cognito's completeNewPasswordChallenge
   * @param email
   * @param newPassword
   * @param requiredAttributeData
   * @param callbacks
   */
  completeNewPasswordChallenge(
    email: string,
    newPassword: string,
    requiredAttributeData,
    callbacks: Partial<NewPasswordChallengeCallback>,
  ) {
    if (!cognitoUser) return;

    //TODO: Prevent app user from sign-up (now only the user feedback is shown, but the user is actually signed-up)
    cognitoUser.completeNewPasswordChallenge(
      newPassword,
      requiredAttributeData,
      {
        ...callbacks,
        onSuccess: (session) => {
          const region = COGNITO_REGION,
            UserPoolId = COGNITO_USER_POOL_ID;

          AWS.config.region = region;

          AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: COGNITO_IDENTITY_POOL_ID,
            Logins: {
              [`cognito-idp.${region}.amazonaws.com/${UserPoolId}`]: session
                .getIdToken()
                .getJwtToken(),
            },
          });

          const idToken = session.getIdToken();
          const groups = idToken.payload["cognito:groups"];

          //TODO: Once we implement more groups, check whether groups contains "Admins"
          if (groups) {
            callbacks.onSuccess?.(session);
          } else {
            callbacks.onFailure?.({ code: "UserNonAdminException" });
            this.signOut();
          }
        },
        onFailure: (err) => callbacks.onFailure?.(err),
      },
    );
  }

  /**
   * Wrapper for Cognito's forgetPassword
   * @param email string
   * @param callbacks ForgetPasswordCallbackType
   */
  forgetPassword(email: string, callbacks: ForgetPasswordCallbackType) {
    cognitoUser = new CognitoUser({ Username: email, Pool: userPool });
    if (!cognitoUser) return;

    cognitoUser.forgotPassword({
      onSuccess: (data) => callbacks.onSuccess?.(data),
      onFailure: (err) => callbacks.onFailure?.(err),
      inputVerificationCode: (data) => callbacks.inputVerificationCode?.(data),
    });
  }

  /**
   * Wrapper for Cognito's changePassword
   * @param email string
   * @param verificationCode string
   * @param newPassword string
   * @param callbacks ConfirmPasswordCallbackType
   */
  confirmPassword(
    email: string,
    verificationCode: string,
    newPassword: string,
    callbacks: ConfirmPasswordCallbackType,
  ) {
    cognitoUser = new CognitoUser({ Username: email, Pool: userPool });
    if (!cognitoUser) return;

    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess: () => callbacks.onSuccess?.(),
      onFailure: (err) => callbacks.onFailure?.(err),
    });
  }

  /**
   * Wrapper for Cognito's getSession
   * @param callback
   */
  getSession(
    callback:
      | ((error: Error, session: null) => void)
      | ((error: null, session: CognitoUserSession) => void),
  ) {
    const cognitoUser = userPool.getCurrentUser();

    if (!cognitoUser) return;

    cognitoUser.getSession(callback);
  }

  currentAuthenticatedUser() {
    return userPool.getCurrentUser() ?? undefined;
  }

  /**
   * Wrapper for Cognito's signOut
   */
  signOut() {
    const cognitoUser = userPool.getCurrentUser();

    if (!cognitoUser) return;

    cognitoUser.signOut();
  }
}

export default CognitoService;
