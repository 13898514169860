import React, { CSSProperties, PropsWithChildren } from "react";
import styles from "./tag.module.css";
import { CloseOutlined } from "@ant-design/icons";

interface TagProps {
  color?: string;
  style?: CSSProperties;
  onDelete?: () => void;
}

const Tag = ({
  children,
  color,
  style,
  onDelete,
}: PropsWithChildren<TagProps>) => (
  <span className={styles.tag} style={{ backgroundColor: color, ...style }}>
    {children}
    {onDelete && (
      <div className={styles.delete}>
        <CloseOutlined style={{ fontSize: "1rem" }} onClick={onDelete} />
      </div>
    )}
  </span>
);

export default Tag;
