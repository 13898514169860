import React, { useState } from "react";
import { Button, Col, Layout, Row, Space, Typography } from "antd";
import ContainerIconComponent from "../../components/icons/container-icon.component";
import { Capitalize } from "../../../core/utils/helper.utils";
import { RoutesEnum } from "../../../constants/routes/app.route";
import { useTranslation } from "react-i18next";
import { useGetPaginatedPlayBooks } from "../../../core/api/primio/primioComponents";
import Table from "../../components/table/table";
import { ContentStatesEnum } from "../../../core/enums/content-states.enum";
import useCreatePlaybookColumnType from "./util/create-playbook-column-type";
import { ModalNames, useModal } from "../../../core/providers/modal.provider";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentUserRole } from "../../../core/redux/selectors/user/user.selector";
import UserRoleEnum from "../../../core/enums/user-role.enum";

const PlaybooksContainer = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const { create } = useCreatePlaybookColumnType();

  // filters
  const [userGroupAcl, setUserGroupAcl] = useState<string[]>();
  const [categoryUids, setCategoryUids] = useState<string[]>();
  const [contentStates, setContentStates] =
    useState<("DRAFT" | "PUBLISHED" | "DELETED")[]>();
  const [learningPathUids, setLearningPathUids] = useState<string[]>();

  const queryParams = {
    includeMedia: false,
    limit: pageSize,
    offset: pageSize * (page - 1),
    q: searchTerm,
  };

  if (userGroupAcl) queryParams["userGroupAcl"] = userGroupAcl;
  if (categoryUids) queryParams["categoryUids"] = categoryUids;
  if (contentStates) queryParams["contentStates"] = contentStates;
  if (learningPathUids) queryParams["learningPathUids"] = learningPathUids;

  const { data: paginatedPlaybooks } = useGetPaginatedPlayBooks({
    queryParams,
  });

  // this one is used to preload the next page so for the end user it just switches data
  useGetPaginatedPlayBooks({
    queryParams: {
      ...queryParams,
      offset: pageSize * page,
    },
  });

  const { t } = useTranslation();
  const { openModal } = useModal();
  const history = useHistory();
  const userRole: UserRoleEnum | undefined = useSelector(selectCurrentUserRole);

  return (
    <>
      <Layout.Content className="Content_wrapper_without_sider">
        <Typography.Title level={2}>
          {Capitalize(t("containers.modules.title"))}
        </Typography.Title>

        <Row style={{ marginBottom: "5em" }}>
          <Col flex={"auto"}>
            <Table
              data={paginatedPlaybooks}
              columns={create}
              rowKey={(p) => p.playbookUid}
              onChange={(pagination, filters) => {
                setContentStates(filters.contentState as ContentStatesEnum[]);
                setCategoryUids(filters.categories as string[]);
                setUserGroupAcl(filters.userGroupAcl as string[]);
                setLearningPathUids(filters.learningPaths as string[]);
              }}
              search={{
                onSearch: setSearchTerm,
                placeholder: Capitalize(
                  t("translations:common.search_x", {
                    name: t("containers.modules.key"),
                  }),
                ),
              }}
              extra={
                <Space>
                  {(userRole === UserRoleEnum.PRIMIO_SUPPORT ||
                    userRole === UserRoleEnum.SYS_ADMIN) && (
                    <Button
                      onClick={() => history.push(RoutesEnum.IMPORT_PLAYBOOKS)}
                    >
                      {Capitalize(t(`common.import`))}
                    </Button>
                  )}
                  <Button
                    type={"primary"}
                    onClick={() => openModal(ModalNames.CREATE_PLAYBOOK)}
                    icon={
                      <ContainerIconComponent screen={RoutesEnum.PLAYBOOKS} />
                    }
                  >
                    {Capitalize(t("common.new-plural"))}{" "}
                    {Capitalize(t(`containers.modules.key`))}
                  </Button>
                </Space>
              }
              onPagination={(page, pageSize) => {
                setPage(page);
                setPageSize(pageSize);
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            />
          </Col>
        </Row>
      </Layout.Content>
    </>
  );
};

export default PlaybooksContainer;
