import React from "react";
import { Form, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../../core/utils/helper.utils";
import TranslateDefaults from "../../../../core/utils/translate-defaults.utils";
import QuestionCardModel from "../../../../core/models/content/card-types/question/question-card.model";
import styles from "../card-types.module.css";
import MediaPreviewComponent from "../../media-types/media-preview.component";

interface OwnProps {
  cardContent: QuestionCardModel;
}

type Props = OwnProps;

const QuestionCardPreviewComponent = (props: Props) => {
  const [t] = useTranslation();
  const { cardContent } = props;

  return (
    <Form
      labelCol={{ span: 6 }}
      layout={"horizontal"}
      labelAlign={"left"}
      className={styles.form_wrapper_horizontal}
    >
      <div className={"Form_item_card"}>
        {cardContent.media && cardContent.media[0] && (
          <Form.Item
            label={
              <Typography.Text strong>
                {Capitalize(t(`form.card.media.label`))}
              </Typography.Text>
            }
          >
            <MediaPreviewComponent media={cardContent.media[0]} />
          </Form.Item>
        )}

        <Form.Item
          label={
            <Typography.Text strong>
              {Capitalize(t(`form.card.question.label`))}
            </Typography.Text>
          }
        >
          <Typography.Text>
            {TranslateDefaults(cardContent.data.question)}
          </Typography.Text>
        </Form.Item>

        <Form.Item
          label={
            <Typography.Text strong>
              {Capitalize(t(`form.card.explanation.label`))}
            </Typography.Text>
          }
        >
          <Typography.Text>
            {TranslateDefaults(cardContent.data.explanation ?? "")}
          </Typography.Text>
        </Form.Item>
      </div>
    </Form>
  );
};

export default QuestionCardPreviewComponent;
