import React from "react";
import Table, { ColumnsType } from "antd/lib/table";
import { Space, Typography } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import ContentActionComponent from "../../components/content-action/content-action.component";
import ContextActionIconComponent from "../../components/icons/context-action-icon.component";
import GetColumnSearchProps from "../../../core/utils/get-column-search-props.utils";
import Moment from "moment";
import { UserModelExtendedInterface } from "../../../core/interfaces/user-model-extended.interface";
import { usePopularUsers } from "../../../core/hooks/user-progress/user-progress.hook";
import { StoreInterface } from "../../../core/redux/stores/root.reducer";
import { useSelector } from "react-redux";
import { selectUserActivities } from "../../../core/redux/selectors/user-progress/user-activity.selector";

const UserActivityOverviewScreen = () => {
  const [t] = useTranslation();
  const { data, isFetching } = usePopularUsers({});
  const userActivities: UserModelExtendedInterface[] = useSelector(
    (state: StoreInterface) => selectUserActivities(state, data),
  );

  const columns: ColumnsType<UserModelExtendedInterface> = [
    {
      key: "name",
      title: (
        <Typography.Text ellipsis style={{ width: 220 }}>
          {Capitalize(t("form.items.name.label"))}
        </Typography.Text>
      ),
      dataIndex: "name",
      width: 200,
      ...GetColumnSearchProps({ dataIndex: "name" }),
      render: function renderText(name: string) {
        return (
          <Typography.Text ellipsis style={{ width: 200 }}>
            {name}
          </Typography.Text>
        );
      },
    },
    {
      key: "firstActiveOn",
      title: (
        <Typography.Text ellipsis style={{ width: 120 }}>
          {Capitalize(t("common.active-since"))}
        </Typography.Text>
      ),
      width: 120,
      sorter: (a, b) => {
        if (a.firstActiveOn && b.firstActiveOn) {
          return +new Date(a.firstActiveOn) - +new Date(b.firstActiveOn);
        }
        return 0;
      },
      render: function renderText(_, user) {
        return (
          <Typography.Text>
            {Moment(user.firstActiveOn).format("DD/MM/YYYY")}
          </Typography.Text>
        );
      },
    },
    {
      key: "lastActive",
      title: (
        <Typography.Text ellipsis style={{ width: 120 }}>
          {Capitalize(t("form.items.last_active_at.label"))}
        </Typography.Text>
      ),
      dataIndex: "lastActive",
      width: 120,
      sorter: (a, b) => {
        if (a.last_active_at && b.last_active_at) {
          return +new Date(a.last_active_at) - +new Date(b.last_active_at);
        }
        return 0;
      },
      render: function renderText(_, data) {
        return (
          <Typography.Text>
            {Moment(data.last_active_at).format("DD/MM/YYYY")}
          </Typography.Text>
        );
      },
    },
    {
      key: "cardsCompleted",
      title: (
        <Typography.Text ellipsis style={{ width: 160 }}>
          {Capitalize(t("content.card.key_plural")) +
            " " +
            t("common.completed")}
        </Typography.Text>
      ),
      dataIndex: "cardsCompleted",
      width: 160,
      sorter: (a, b) => Number(a.cardsCompleted) - Number(b.cardsCompleted),
      render: function renderText(data: number | undefined) {
        const cardsCompleted = data || data === 0 ? data : Number.NaN;
        return (
          <Typography.Text ellipsis style={{ width: 160 }}>
            {cardsCompleted}
          </Typography.Text>
        );
      },
    },
    {
      dataIndex: "action",
      width: 1,
      render: function showActions(_, data) {
        return <Space size={"middle"}>{handleShowActions(data)}</Space>;
      },
    },
  ];

  return (
    <Table<UserModelExtendedInterface>
      columns={columns}
      dataSource={userActivities}
      rowKey={(data) => data.username}
      pagination={{ pageSize: 200 }}
      loading={isFetching}
      tableLayout={"fixed"}
      scroll={{ x: true }}
      size={"middle"}
    />
  );

  function handleShowActions(data: UserModelExtendedInterface) {
    return (
      <Link to={{ pathname: `/users/${data.username as string}` }}>
        <ContentActionComponent
          icon={<ContextActionIconComponent action={"open"} />}
        />
      </Link>
    );
  }
};

export default UserActivityOverviewScreen;
