import React, {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { UserGroupCreateInterface } from "../../../core/interfaces/user-group-create.interface";
import { StoreInterface } from "../../../core/redux/stores/root.reducer";
import { FormInstance, useForm } from "antd/lib/form/Form";
import { Capitalize } from "../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";
import { selectUsers } from "../../../core/redux/selectors/user/user.selector";
import { Form, Input, Switch, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import AppConfig from "../../../constants/config/app.config";
import UserSelectComponent from "./select/user-select.component";
import UserGroupModel from "../../../core/models/user-group/user-group.model";
import UserModel from "../../../core/models/user/user.model";
import CharacterCounter from "../form-builder/components/character-counter";
import styles from "../card-types/card-types.module.css";
import PlaybooksSelectComponent, {
  SelectedPlaybookUidsType,
} from "./select/playbooks-select.component";
import PlaybookModel from "../../../core/models/content/playbook.model";
import {
  playbooksSelector,
  selectPlaybooksFilteredByUserGroup,
} from "../../../core/redux/selectors/content/content.selector";
import { isRoleGroup } from "../../../core/utils/filter-role-groups";

export interface CreateUserGroupFormRef {
  form: FormInstance<UserGroupCreateInterface>;
  focusTitle: () => void;
}

interface OwnProps {
  group: UserGroupModel | undefined;
  groupUsers: UserModel[];
}

type Props = OwnProps;

const CreateUserGroupsForm = forwardRef(
  (props: Props, ref: Ref<CreateUserGroupFormRef>) => {
    const [t] = useTranslation();
    const { group, groupUsers } = props;
    const users: UserModel[] = useSelector((state: StoreInterface) =>
      selectUsers(state),
    );
    const [form] = useForm<UserGroupCreateInterface>();
    const [title, setTitle] = useState<string>(
      group && group.title ? group.title.split(" ").join("").trim() : "",
    );
    const [description, setDescription] = useState<string>(
      group && group.description ? group.description : "",
    );
    const [userNames, setUserNames] = useState<string[] | []>(
      group && groupUsers ? groupUsers.map((user) => user.username) : [],
    );
    const [switchAllUsers, setSwitchAllUsers] = useState<boolean>(
      groupUsers && users ? groupUsers.length >= users.length : false,
    );
    const allPlaybooks: PlaybookModel[] = useSelector(playbooksSelector);
    const userGroupFromPlaybooks = selectPlaybooksFilteredByUserGroup(
      allPlaybooks,
      group,
    );
    const [playbookUids, setPlaybookUids] = useState<
      SelectedPlaybookUidsType[]
    >(
      userGroupFromPlaybooks.map((p) => ({
        playbookUid: p.playbookUid,
        isShared: !!p.apiKeyClientUid,
      })),
    );

    const allowContentSelection = isRoleGroup(group?.title);
    const titleInput = useRef<typeof Input | any>(null);
    const titleMaxLength = AppConfig.userGroupTitleMaxLength;
    const descriptionMaxLength = AppConfig.userGroupDescriptionMaxLength;

    useEffect(() => {
      setUserNames(groupUsers.map((user) => user.username));
      setUserNamesFormField(groupUsers.map((user) => user.username));
    }, [groupUsers]);

    useImperativeHandle(ref, () => ({ form, focusTitle }));

    function focusTitle() {
      if (titleInput.current) {
        titleInput.current.focus();
      }
    }

    return (
      <Form form={form} labelCol={{ span: 6 }} labelAlign={"left"}>
        <Form.Item
          initialValue={title}
          name={"title"}
          label={Capitalize(t("form.items.name.label"))}
          rules={getRules("title")}
        >
          <Input
            ref={titleInput}
            disabled={group ? !!group.title : false}
            value={title}
            type={"text"}
            suffix={
              <Typography.Text>
                {title ? title.length : 0}/{titleMaxLength}
              </Typography.Text>
            }
            maxLength={titleMaxLength}
            style={{ width: "80%" }}
            autoFocus
            placeholder={t("form.placeholders.type-of_x", {
              item: t("form.items.name.label"),
              field: t("containers.user-groups.key"),
            })}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          initialValue={description}
          name={"description"}
          label={Capitalize(t("form.card.description.label"))}
          rules={getRules("description")}
        >
          <div>
            <TextArea
              value={description}
              rows={4}
              style={{ width: "80%" }}
              maxLength={descriptionMaxLength}
              placeholder={t("form.placeholders.describe_x", {
                item: t("containers.user-groups.key"),
              })}
              onChange={(e) => setDescription(e.target.value)}
            />
            <CharacterCounter
              currentLength={description.length}
              maxLength={descriptionMaxLength}
              className={styles.character_counter}
            />
          </div>
        </Form.Item>
        {!allowContentSelection && (
          <Form.Item
            initialValue={playbookUids}
            name={"playbookUids"}
            label={Capitalize(t("form.items.content.label"))}
          >
            {/*TODO: only be able to select open or content for this user group only */}
            <PlaybooksSelectComponent
              playbookUids={playbookUids.map((p) => p.playbookUid)}
              onChange={(value) => {
                form.setFields([{ name: "playbookUids", value }]);
                setPlaybookUids(value);
              }}
            />
          </Form.Item>
        )}
        <Form.Item
          initialValue={userNames}
          name={"usernames"}
          label={Capitalize(t("containers.users.key_plural"))}
        >
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <UserSelectComponent
              userNames={userNames}
              users={users}
              onChange={handleOnChangeUsernames}
            />
            <Form.Item
              label={Capitalize(t("containers.users.sidebar"))}
              style={{ marginLeft: "3rem", marginBottom: "0" }}
              labelAlign={"right"}
            >
              <Switch
                checked={switchAllUsers}
                onChange={handleSelectAllUsers}
              />
            </Form.Item>
          </div>
        </Form.Item>
      </Form>
    );

    function handleOnChangeUsernames(value: string[]) {
      setSwitchAllUsers(users ? value.length >= users.length : false);
      setUserNamesFormField(value);
      setUserNames(value);
    }

    function handleSelectAllUsers(value: boolean) {
      if (!users) {
        return;
      }

      setSwitchAllUsers(value);

      const userNames = value ? [...users.map((user) => user.username)] : [];
      setUserNamesFormField(userNames);

      setUserNames(userNames);
    }

    function setUserNamesFormField(value: string[]) {
      form.setFields([{ name: "usernames", value }]);
    }

    function getRules(formItem: string) {
      const maxLength =
        formItem === "title" ? titleMaxLength : descriptionMaxLength;
      const item =
        formItem === "title"
          ? "form.items.name.label"
          : "form.card.description.label";

      return [
        {
          max: maxLength,
          message: Capitalize(
            t("errors.max-length", {
              field: t(item),
              amount: maxLength,
            }),
          ),
        },
        {
          required: true,
          message: t("errors.required", { item: t(item) }),
        },
      ];
    }
  },
);

CreateUserGroupsForm.displayName = "CreateUserGroupsForm";

export default CreateUserGroupsForm;
