import React, { ReactNode, useState } from "react";

import { Divider, Dropdown, Input, Tag, Typography } from "antd";
import { Capitalize } from "../../../core/utils/helper.utils";
import { CloseOutlined } from "@ant-design/icons";
import styles from "./inline-attribute-manager.module.css";
import { useTranslation } from "react-i18next";

interface Props<Type> {
  data: Type[];
  allData: Type[];
  label?: string;
  renderItem: (item: Type) => ReactNode;
  keyExtractor?: (item: Type) => string;
  onCreate: (name: string) => void;
  onAdd: (item: Type) => void;
  onRemove: (item: Type) => void;
  onClick?: (item: Type) => void;
  searchKey?: string;
  renderItemExtra?: (item: Type) => ReactNode;
  disabled?: boolean;
}

const InlineAttributeManager = <Type,>({
  data,
  allData,
  label,
  renderItem,
  keyExtractor,
  onCreate,
  onAdd,
  onRemove,
  onClick,
  searchKey,
  renderItemExtra,
  disabled,
}: Props<Type>) => {
  const { t } = useTranslation();

  const [query, setQuery] = useState("");
  const [open, setOpen] = useState(false);

  const filteredData = allData.filter((item) => {
    if (searchKey) {
      return item[searchKey].toLowerCase().includes(query.toLowerCase());
    } else if (typeof item === "string") {
      return item.toLowerCase().includes(query.toLowerCase());
    }
  });

  const getKey = (item: Type, index: number) => {
    if (keyExtractor) {
      return keyExtractor(item);
    } else {
      return index;
    }
  };

  return (
    <Dropdown
      disabled={disabled}
      trigger={["click"]}
      open={open}
      onOpenChange={(visible) => {
        setOpen(visible);
        !visible && setQuery("");
      }}
      dropdownRender={() => (
        <div
          className="ant-dropdown-menu"
          style={{
            width: 250,
            display: "flex",
            flexDirection: "column",
            padding: 0,
          }}
        >
          <div className={styles.attribute_manager}>
            {data.length > 0 && (
              <>
                <ul
                  style={{
                    paddingTop: 4,
                    paddingBottom: 4,
                    maxHeight: 250,
                    overflowY: "auto",
                  }}
                >
                  {data.map((item, index) => (
                    <li
                      key={getKey(item, index)}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          overflow: "hidden",
                          cursor: onClick ? "pointer" : "default",
                        }}
                        onClick={() => onClick && onClick(item)}
                      >
                        {renderItem(item)}
                      </div>
                      <div
                        className={styles.action}
                        style={{ cursor: "pointer" }}
                        onClick={() => onRemove(item)}
                      >
                        <CloseOutlined />
                      </div>
                    </li>
                  ))}
                </ul>
                <Divider style={{ margin: 0 }} />
              </>
            )}
            <div style={{ marginTop: 8, paddingLeft: 8, paddingRight: 8 }}>
              <Input
                placeholder={Capitalize(t("translations:common.search"))}
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                onPressEnter={() => {
                  if (filteredData.length !== 0) {
                    return;
                  }
                  onCreate(query.trim());
                  setQuery("");
                }}
              />
              <div style={{ marginTop: 6 }}>
                <Typography.Text style={{ fontSize: 12 }}>
                  {label}
                </Typography.Text>
              </div>
            </div>
            <div
              style={{
                maxHeight: 250,
                overflow: "scroll",
              }}
            >
              {query.trim() !== "" && (
                <ul>
                  <li>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        onCreate(query.trim());
                        setQuery("");
                      }}
                    >
                      <Typography.Text style={{ marginRight: 6 }}>
                        {Capitalize(t("translations:common.create"))}
                      </Typography.Text>
                      <Tag>{query}</Tag>
                    </div>
                  </li>
                </ul>
              )}

              {filteredData.length > 0 && (
                <ul>
                  {filteredData.map((item, index) => (
                    <li
                      key={getKey(item, index)}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          cursor: "pointer",
                          overflow: "hidden",
                        }}
                        onClick={() => onAdd(item)}
                      >
                        {renderItem(item)}
                      </div>
                      {renderItemExtra && (
                        <div style={{ paddingLeft: 4 }}>
                          {renderItemExtra(item)}
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      )}
    >
      <div
        style={{
          cursor: disabled ? "default" : "pointer",
          minHeight: 25,
        }}
      >
        {data.slice(0, 2).map((item, index) => (
          <div style={{ display: "inline-block" }} key={getKey(item, index)}>
            {renderItem(item)}
          </div>
        ))}
        {data.length > 2 && (
          <div style={{ display: "inline-block" }}>
            <span style={{ color: "gray" }}>+{data.length - 2}</span>
          </div>
        )}
      </div>
    </Dropdown>
  );
};

export default InlineAttributeManager;
