import React, { useState } from "react";
import {
  selectCardViaUid,
  selectChapterViaUid,
  selectCollectionViaUid,
  selectPlaybookViaUid,
} from "../../../core/redux/selectors/content/content.selector";
import { useDispatch, useSelector } from "react-redux";
import { BaseNotificationData } from "../../../core/models/notification/base-notification.model";
import { TableRowSelection } from "antd/lib/table/interface";
import { StoreInterface } from "../../../core/redux/stores/root.reducer";
import { ColumnsType } from "antd/lib/table";
import { Modal, Space, Table, Tag, Typography } from "antd";
import { grey } from "@ant-design/colors";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import {
  NotifiableTypeEnum,
  NotificationTypesEnum,
} from "../../../core/enums/notification-types.enum";
import { Link } from "react-router-dom";
import NotificationAction from "../../../core/redux/stores/notification/notification.action";
import ContextActionIconComponent from "../../components/icons/context-action-icon.component";
import ContentActionComponent from "../../components/content-action/content-action.component";
import TranslateDefaults from "../../../core/utils/translate-defaults.utils";
import ContentIconComponent from "../../components/icons/content-icon.component";
import Paragraph from "antd/lib/typography/Paragraph";
import colors from "../../styles/colors";
import NotificationTypes from "../../../core/types/notification.types";
import ContentType from "../../../core/types/content.type";
import Moment from "moment";

interface OwnProps {
  notifications: NotificationTypes[];
  loading: boolean;
}

type Props = OwnProps;

const NotificationsOverviewScreen = (props: Props) => {
  const { t } = useTranslation();
  const { notifications, loading } = props;
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>("");
  const [selectedRow, setSelectedRows] = useState<NotificationTypes[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const dispatch = useDispatch();

  const columns: ColumnsType<NotificationTypes> = [
    {
      key: "notificationType",
      title: Capitalize(t("screens.notifications.type.label")),
      dataIndex: "notificationType",
      width: 180,
      filters: [
        {
          text: Capitalize(t("screens.notifications.type.ANNOUNCEMENT")),
          value: NotificationTypesEnum.ANNOUNCEMENT,
        },
        {
          text: Capitalize(t("screens.notifications.type.CONTENT")),
          value: NotificationTypesEnum.CONTENT,
        },
      ],
      onFilter: function filterText(value, notification) {
        if (typeof value === "string") {
          return notification.notificationType.includes(value);
        }
        return false;
      },
      render: function renderText(_, notification) {
        return (
          <Typography.Text>
            {Capitalize(
              t(`screens.notifications.type.${notification.notificationType}`),
            )}
          </Typography.Text>
        );
      },
    },
    {
      key: "data",
      title: Capitalize(t("form.items.content.label")),
      dataIndex: "data",
      width: 220,
      render: (_, notification) => <RenderLinkedContent {...notification} />,
    },
    {
      key: "scheduledAt",
      title: Capitalize(t("form.items.send.send-at")),
      dataIndex: "scheduledAt",
      width: 140,
      sorter: (a, b) => +new Date(a.scheduledAt) - +new Date(b.scheduledAt),
      render: function renderText(_, notification) {
        return (
          <Typography.Text>
            {Moment(notification.scheduledAt).format("DD/MM/YYYY (LT)")}
          </Typography.Text>
        );
      },
    },
    {
      key: "notifiable",
      title: Capitalize(t("form.items.send.scheduled-to")),
      dataIndex: "notifiable",
      width: 120,
      render: function renderTags(_, notification, index) {
        return renderRecipients(notification, index);
      },
    },
    {
      key: "seenBy",
      title: Capitalize(t("form.items.send.seen-by")),
      dataIndex: "seenBy",
      width: 120,
      sorter: (a, b) => a.seenBy - b.seenBy,
    },
    {
      key: "action",
      width: 1,
      render: function showActions(_, notification) {
        return (
          <Space size={"middle"} style={{ float: "right" }}>
            <Link
              to={{
                pathname: `/notifications/${notification.notificationUid as string}`,
              }}
            >
              <ContentActionComponent
                icon={<ContextActionIconComponent action={"open"} />}
              />
            </Link>
          </Space>
        );
      },
    },
  ];

  const rowSelection: TableRowSelection<NotificationTypes> = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
    onSelect: (_r, _s, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    selections: [
      {
        key: "delete",
        text: (
          <>
            <ContextActionIconComponent
              style={{ color: colors.secondary, paddingRight: "1rem" }}
              action={"delete"}
            />
            <Typography.Text style={{ color: colors.secondary }}>
              {Capitalize(t("common.delete"))}
            </Typography.Text>
          </>
        ),
        onSelect: () => deleteScheduledNotification(selectedRow),
      },
    ],
  };

  return (
    <Table<NotificationTypes>
      dataSource={notifications}
      columns={columns}
      rowKey={(data) => data.notificationUid}
      rowSelection={rowSelection}
      pagination={false}
      loading={loading || isSubmitting}
      tableLayout={"fixed"}
      scroll={{ x: true }}
      size={"middle"}
    />
  );

  function renderRecipients(notification: NotificationTypes, index: number) {
    const recipients: string[] | undefined = notification.notifiable.recipients;

    if (
      !recipients &&
      notification.notifiable.notifiableType === NotifiableTypeEnum.USERS
    ) {
      return (
        <div key={index}>
          <Paragraph
            style={{ overflow: "hidden", width: "12rem" }}
            ellipsis={{ rows: 3 }}
          >
            <Tag>{Capitalize(t("common.everyone"))}</Tag>
          </Paragraph>
        </div>
      );
    }

    if (
      notification.notifiable.notifiableType === NotifiableTypeEnum.USER_GROUPS
    ) {
      return (
        <div key={index}>
          <Paragraph
            style={{ overflow: "hidden", width: "12rem" }}
            ellipsis={{ rows: 3 }}
          >
            {recipients?.map((recipients, index) => (
              <Tag key={index}>{Capitalize(recipients)}</Tag>
            ))}
          </Paragraph>
        </div>
      );
    }

    return (
      <Typography.Text>
        {recipients?.length +
          " " +
          Capitalize(t("containers.users.key_plural"))}
      </Typography.Text>
    );
  }

  function deleteScheduledNotification(res: NotificationTypes[]) {
    const notificationNames: string[] = [];
    for (let i = 0; i < res.length; i++) {
      const title =
        res[i] === res[res.length - 1]
          ? res[i].data.title
          : res[i].data.title + ", ";
      notificationNames.push(title);
    }

    const isDisabled: boolean = res.some((not) => not.sentAt);

    const title = Capitalize(
      t("errors.warnings.delete", {
        field: Capitalize(t(`containers.notifications.key_plural`)),
      }),
    );

    const disabledTitle = Capitalize(
      t("errors.warnings.delete-not", {
        field: `${Capitalize(t("screens.notifications.state.SENT"))} ${t(
          "containers.notifications.key_plural",
        )}`,
      }),
    );

    Modal.confirm({
      title: isDisabled ? disabledTitle : title,
      content: (
        <Typography.Text>
          {Capitalize(t("containers.notifications.key_plural"))}:{" "}
          {notificationNames}
        </Typography.Text>
      ),
      icon: (
        <ContextActionIconComponent
          action={"delete"}
          style={{ color: colors.secondary }}
        />
      ),
      cancelText: Capitalize(t("common.cancel")),
      cancelButtonProps: { type: "text" },
      okText: Capitalize(t("common.delete")),
      okButtonProps: { disabled: isDisabled },
      onOk() {
        setIsSubmitting(true);
        res.forEach((notification: NotificationTypes) => {
          dispatch(
            NotificationAction.deleteScheduledNotification(notification),
          ).then(() => {
            setIsSubmitting(false);
          });
        });
      },
    });
  }
};

const RenderLinkedContent = (notification: NotificationTypes) => {
  const data: BaseNotificationData = notification.data;
  let content: ContentType | undefined;

  if (notification.notificationType === NotificationTypesEnum.CONTENT) {
    const contentData: ContentType[] = [
      useSelector((state: StoreInterface) =>
        selectPlaybookViaUid(state, notification.data.playbookUid),
      ),
      useSelector((state: StoreInterface) =>
        selectChapterViaUid(state, notification.data.chapterUid),
      ),
      useSelector((state: StoreInterface) =>
        selectCollectionViaUid(state, notification.data.collectionUid),
      ),
      useSelector((state: StoreInterface) =>
        selectCardViaUid(state, notification.data.cardUid),
      ),
    ];

    if (contentData[contentData.length - 1] !== undefined) {
      content = contentData[contentData.length - 1];
    } else if (contentData[contentData.length - 2] !== undefined) {
      content = contentData[contentData.length - 2];
    } else if (contentData[contentData.length - 3]) {
      content = contentData[contentData.length - 3];
    }
  }

  return (
    <div style={{ display: "flex", flexFlow: "column" }}>
      <Typography.Text ellipsis strong style={{ width: 220 }}>
        {data.title}
      </Typography.Text>

      <Typography.Text ellipsis type={"secondary"} style={{ width: 220 }}>
        {data.subtitle}
      </Typography.Text>

      <Typography.Text ellipsis style={{ color: grey[6], width: 220 }}>
        {data.message}
      </Typography.Text>

      {content && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingTop: ".5rem",
          }}
        >
          <ContentIconComponent
            style={{
              paddingRight: "1.8rem",
              width: "1.8rem",
              color: grey[0],
            }}
            contentType={content.contentType}
          />
          <Typography.Text type={"secondary"} style={{ fontSize: "1rem" }}>
            {TranslateDefaults(content.title)}
          </Typography.Text>
        </div>
      )}
    </div>
  );
};

export default NotificationsOverviewScreen;
