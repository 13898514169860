import React, { useEffect, useState } from "react";
import { BaseLearningPathContent } from "../../../core/api/primio/primioSchemas";
import { useLearningPath } from "../../../core/providers/learning-path.provider";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import LearningPathContentListItem from "./learning-path-content-list-item";
import { Empty } from "antd";

function reorderArray<Type extends { contentUid: string }>(
  array: Type[],
  contentUid: string,
  targetIndex: number,
) {
  const index = array.findIndex((item) => item.contentUid === contentUid);
  if (index === -1) {
    // If the UID is not found in the array, return the original array
    return array;
  }

  // Remove the item with the specified UID from the array
  const removedItem = array.splice(index, 1)[0];

  // Adjust the target index if it's out of bounds
  if (targetIndex < 0) {
    targetIndex = 0;
  } else if (targetIndex > array.length) {
    targetIndex = array.length;
  }

  // Insert the removed item at the target index
  array.splice(targetIndex, 0, removedItem);

  return array;
}

const LearningPathContentList = ({
  content,
}: {
  content: BaseLearningPathContent[];
}) => {
  const { updateContent, learningPath } = useLearningPath();
  const [listItems, setListItems] =
    useState<BaseLearningPathContent[]>(content);

  useEffect(() => {
    setListItems(content);
  }, [content]);

  const onDragEnd = async (result: DropResult) => {
    if (result.destination) {
      const reorderedContent = reorderArray(
        listItems,
        result.draggableId,
        result.destination.index,
      );
      setListItems(reorderedContent);
      await updateContent(reorderedContent);
    }
  };

  return (
    <div>
      {content.length === 0 && (
        <Empty
          description={"Training does not have content"}
          style={{ marginBottom: 10 }}
        />
      )}

      <div style={{ gap: 10, display: "grid" }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={"droppable"}>
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {content.map((content, index) => (
                  <Draggable
                    key={content.contentUid}
                    draggableId={content.contentUid}
                    index={index}
                    isDragDisabled={learningPath.state === "POSTED"}
                  >
                    {(provided) => (
                      <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        style={{
                          ...provided.draggableProps.style,
                          marginBottom: 10,
                        }}
                      >
                        <LearningPathContentListItem content={content} />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

export default LearningPathContentList;
