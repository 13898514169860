import React, { useRef } from "react";
import CreateCategoryForm, {
  CreateCategoryFormRef,
} from "../forms/create-category.form";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import TranslateDefaults from "../../../core/utils/translate-defaults.utils";
import { Category } from "../../../core/api/primio/primioSchemas";
import {
  useCreateCategory,
  useUpdateCategory,
} from "../../../core/api/primio/primioComponents";
import { useQueryClient } from "@tanstack/react-query";

interface OwnProps {
  category?: Category;
  visible: boolean;
  onCancel: () => void;
}

type Props = OwnProps;

const CreateCategoryModal = ({ category, visible, onCancel }: Props) => {
  const [t] = useTranslation();
  const createCategoryFormRef = useRef<CreateCategoryFormRef>(null);
  const queryClient = useQueryClient();

  const { mutateAsync: createCategory, isLoading } = useCreateCategory({
    onSuccess: () => queryClient.invalidateQueries(["categories"]),
  });
  const { mutateAsync: editCategory } = useUpdateCategory({
    onSuccess: () => {
      queryClient.invalidateQueries(["categories"]);
      queryClient.invalidateQueries(["v2", "playbooks"]);

      if (category) {
        queryClient.invalidateQueries(["categories", category.categoryUid]);
      }
    },
  });

  return (
    <Modal
      open={visible}
      title={renderModalTitle()}
      destroyOnClose
      width={"80rem"}
      okText={renderOnOkLabel()}
      onOk={handleOnOk}
      cancelText={Capitalize(t("common.cancel"))}
      onCancel={handleOnCancel}
      confirmLoading={isLoading}
    >
      <CreateCategoryForm ref={createCategoryFormRef} category={category} />
    </Modal>
  );

  function handleOnOk() {
    if (createCategoryFormRef.current) {
      createCategoryFormRef.current.form.validateFields().then(async (res) => {
        const playbookUids = res.playbookUids.map((p) => p.playbookUid);
        // Create category
        if (!category) {
          await createCategory({
            body: {
              playbookUids,
              title: res.title,
            },
          });
          return handleOnCancel();
        }

        // Edit category
        await editCategory({
          pathParams: {
            categoryUid: category.categoryUid,
          },
          body: {
            playbookUids,
            title: res.title,
            sort: category.sort,
          },
        });
        return handleOnCancel();
      });
    }
  }

  function handleOnCancel() {
    onCancel();
    if (createCategoryFormRef.current) {
      createCategoryFormRef.current.form.resetFields();
    }
  }

  function renderModalTitle() {
    if (category && category.categoryUid) {
      return TranslateDefaults(category.title);
    }
    return (
      Capitalize(t("common.new-plural")) +
      " " +
      Capitalize(t("containers.categories.key"))
    );
  }

  function renderOnOkLabel() {
    if (category && category.categoryUid) {
      return Capitalize(t("common.edit"));
    }
    return Capitalize(t("common.create"));
  }
};

export default CreateCategoryModal;
