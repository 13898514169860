import React, {
  forwardRef,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { ContentCascaderInterface } from "../../../core/interfaces/content-cascader.interface";
import { FormInstance, useForm } from "antd/lib/form/Form";
import { LearningPathTypeEnum } from "../../../core/enums/learning-path-state.enum";
import { useTranslation } from "react-i18next";
import { Capitalize } from "../../../core/utils/helper.utils";
import { Button, Divider, Form, Input, Typography } from "antd";
import { BellOutlined, CloseOutlined, FolderOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import AppConfig from "../../../constants/config/app.config";
import ProgressBasedContentModel from "../../../core/models/learning-path/progress-based-content.model";
import TimeBasedContentModel from "../../../core/models/learning-path/time-based-content.model";
import LearningPathModel from "../../../core/models/learning-path/learning-path.model";
import ContentTypesEnum from "../../../core/enums/content-types.enum";
import styles from "../learning-path/learning-path.module.css";
import PlaybooksSelectComponent from "./select/playbooks-select.component";
import { Playbook } from "../../../core/api/primio/primioSchemas";

export interface CreateLearningPathContentInterface {
  content: ContentCascaderInterface;
  title?: string;
  message?: string;
}

export interface CreateLearningPathContentRef {
  form: FormInstance<CreateLearningPathContentInterface>;
}

interface Props {
  learningPath: LearningPathModel;
  onOk: () => void;
  onCancel: () => void;
  content?: TimeBasedContentModel | ProgressBasedContentModel;
  userGroups?: string[];
}

const CreateLearningPathContentForm = forwardRef(
  (
    { learningPath, onOk, onCancel, userGroups = [], content }: Props,
    ref: Ref<CreateLearningPathContentRef>,
  ) => {
    const [t] = useTranslation();
    const [form] = useForm<CreateLearningPathContentInterface>();
    const [title, setTitle] = useState<string>();
    const [message, setMessage] = useState<string>();
    const [playbookUid, setPlaybookUid] = useState(
      content ? content.contentUid : undefined,
    );

    const [learningPathUserGroups, setLearningPathUserGroups] = useState<
      string[] | undefined
    >([]);
    const [enableNotificationFields, setEnableNotificationFields] =
      useState(false);
    const titleMaxLength = AppConfig.learningPathNotificationTitleMaxLength;
    const messageMaxLength = AppConfig.learningPathNotificationMessageMaxLength;

    useImperativeHandle(ref, () => ({ form }));

    useEffect(() => {
      if (!learningPath) {
        setEnableNotificationFields(false);
        return;
      }

      switch (learningPath.learningPathType) {
        case LearningPathTypeEnum.TIME_BASED:
          if (learningPath.content.length > 0 && content?.sort !== 1) {
            setEnableNotificationFields(true);
          } else {
            setEnableNotificationFields(false);
          }
          break;
        case LearningPathTypeEnum.PROGRESS_BASED:
          setEnableNotificationFields(false);
          break;
        case LearningPathTypeEnum.SCHEDULE_BASED:
          setEnableNotificationFields(true);
          break;
      }
    }, [learningPath]);

    useEffect(() => {
      if (learningPath.userGroups && learningPath.userGroups.length > 0) {
        setLearningPathUserGroups(learningPath.userGroups);
        return;
      }
      setLearningPathUserGroups(userGroups);
    }, [learningPath.userGroups]);

    useEffect(() => {
      if (content && content.learningPathType) {
        if (content.learningPathType === LearningPathTypeEnum.TIME_BASED) {
          const { title, message } = content.data.notification;
          if (title && message) {
            setTitle(title);
            setMessage(message);
            form.setFields([
              { name: "title", value: title },
              { name: "message", value: message },
            ]);
            return;
          }
        }
      }

      switch (learningPath.learningPathType) {
        case LearningPathTypeEnum.TIME_BASED:
        case LearningPathTypeEnum.PROGRESS_BASED:
          if (
            learningPath.content.length > 0 &&
            !content &&
            !title &&
            !message
          ) {
            setTitle(AppConfig.learningPathNotificationTitleDefault);
            setMessage(AppConfig.learningPathNotificationMessageDefault);

            form.setFields([
              {
                name: "title",
                value: AppConfig.learningPathNotificationTitleDefault,
              },
              {
                name: "message",
                value: AppConfig.learningPathNotificationMessageDefault,
              },
            ]);
          }
          break;
        case LearningPathTypeEnum.SCHEDULE_BASED:
          if (title === undefined) {
            setTitle(AppConfig.learningPathNotificationTitleDefault);
            form.setFields([
              {
                name: "title",
                value: AppConfig.learningPathNotificationTitleDefault,
              },
            ]);
          }

          if (message === undefined) {
            setMessage(AppConfig.learningPathNotificationMessageDefault);
            form.setFields([
              {
                name: "message",
                value: AppConfig.learningPathNotificationMessageDefault,
              },
            ]);
          }
          break;
      }
    }, [learningPath, content]);

    const filterPlaybooks = useCallback(
      (playbook: Playbook) => {
        // Filter out deleted and draft playbooks
        return playbook.contentState === "PUBLISHED";
      },
      [learningPathUserGroups, learningPath],
    );

    return (
      <Form
        form={form}
        labelCol={{ span: 6 }}
        labelAlign={"left"}
        className={styles.form}
      >
        <Form.Item style={{ margin: 0 }}>
          <div className={styles.form_title}>
            <Typography.Title level={5} style={{ margin: 0 }}>
              {Capitalize(
                t("common.add-to", { item: t("containers.learning-path.key") }),
              )}
            </Typography.Title>
            <CloseOutlined onClick={onCancel} />
          </div>
          <Divider />
        </Form.Item>

        <Form.Item style={{ marginBottom: "2rem" }}>
          <Typography.Text>
            <FolderOutlined style={{ marginRight: ".5rem" }} />
            {Capitalize(
              t("form.placeholders.select_x", { x: t("content.playbook.key") }),
            )}
          </Typography.Text>
        </Form.Item>

        <Form.Item
          initialValue={playbookUid}
          name={"content"}
          rules={getRules("content")}
        >
          <PlaybooksSelectComponent
            playbookUids={playbookUid ? [playbookUid] : []}
            filter={filterPlaybooks}
            onChange={(value) => {
              if (value.length === 0) return;
              handleSetContent(value[0].playbookUid);
            }}
            mode={"single"}
          />
        </Form.Item>

        {enableNotificationFields && (
          <>
            <Form.Item style={{ margin: "4rem 0 2rem 0" }}>
              <Typography.Text>
                <BellOutlined style={{ marginRight: ".5rem" }} />
                {Capitalize(t("containers.notifications.key"))}
              </Typography.Text>
            </Form.Item>

            <Form.Item
              name={"title"}
              initialValue={title}
              label={Capitalize(t("form.card.title.label"))}
              rules={getRules("title")}
            >
              <Input
                value={title}
                type={"text"}
                suffix={
                  <Typography.Text>
                    {title ? title.length : 0}/{titleMaxLength}
                  </Typography.Text>
                }
                maxLength={titleMaxLength}
                style={{ width: "80%" }}
                placeholder={t("form.placeholders.type-of_x", {
                  item: t("form.card.title.label"),
                  field: t("containers.notifications.key"),
                })}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              name={"message"}
              initialValue={message}
              label={Capitalize(t("form.items.text.label"))}
              rules={getRules("description")}
            >
              <TextArea
                rows={4}
                value={message}
                showCount={true}
                style={{ width: "80%" }}
                maxLength={messageMaxLength}
                placeholder={t("form.items.text.placeholder")}
                onChange={(e) => setMessage(e.target.value)}
              />
            </Form.Item>
          </>
        )}

        <Form.Item style={{ textAlign: "right", margin: "0 0 2rem" }}>
          <Divider style={{ margin: "2rem 0" }} />
          <Button type={"text"} onClick={onCancel}>
            {Capitalize(t("common.cancel"))}
          </Button>
          <Button
            type={"primary"}
            htmlType={"submit"}
            onClick={onOk}
            disabled={disableButton()}
          >
            {content
              ? Capitalize(t("common.edit"))
              : Capitalize(t("common.add"))}
          </Button>
        </Form.Item>
      </Form>
    );

    function disableButton() {
      if (!content) {
        return !playbookUid;
      }
    }

    function handleSetContent(playbookUid: string) {
      setPlaybookUid(playbookUid);
      form.setFields([
        {
          name: "content",
          value: { value: playbookUid, contentType: ContentTypesEnum.PLAYBOOK },
        },
      ]);
      form.validateFields(["content"]);
    }

    function getRules(formItem: string) {
      const maxLength =
        formItem === "title" ? titleMaxLength : messageMaxLength;

      if (formItem === "content") {
        return [
          {
            required: true,
            message: Capitalize(t("translations:errors.required-generic")),
          },
        ];
      }

      return [
        {
          max: maxLength,
          message: Capitalize(
            t("errors.max-length", {
              field: t(`form.card.${formItem}.label`),
              amount: maxLength,
            }),
          ),
        },
        {
          required: true,
          message: t("errors.required", {
            item: t(`form.card.${formItem}.label`),
          }),
        },
      ];
    }
  },
);

CreateLearningPathContentForm.displayName = "CreateLearningPathContentForm";

export default CreateLearningPathContentForm;
