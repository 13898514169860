export const SYS_ADMIN_GROUP = "SystemAdmins";
export const PRIMIO_SUPPORT_GROUP = "PrimioSupport";
export const ADMIN_GROUP = "Admins";
export const UserGroupRoles = [
  SYS_ADMIN_GROUP,
  PRIMIO_SUPPORT_GROUP,
  ADMIN_GROUP,
];
export const API_KEY_HASH_KEY = "2D83BC6DC97E468412AD977828692";

export const learningPathColors = [
  "#E24333",
  "#F78248",
  "#FFB052",
  "#EA8181",
  "#407A87",
  "#6C938F",
  "#6AADDB",
  "#6D89CF",
  "#875BB2",
];

export const tagColors = [
  "#368eff",
  "#0bb86e",
  "#f6bb56",
  "#f378c2",
  "#3ebce2",
  "#886ef1",
  "#e95656",
  "#f1a56e",
];
