import React, { useState } from "react";
import { TableRowSelection } from "antd/lib/table/interface";
import { ColumnsType } from "antd/lib/table";
import { Space, Table, Typography } from "antd";
import { Capitalize } from "../../../core/utils/helper.utils";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ContextActionIconComponent from "../../components/icons/context-action-icon.component";
import ContainerIconComponent from "../../components/icons/container-icon.component";
import GetColumnSearchProps from "../../../core/utils/get-column-search-props.utils";
import ContentActionComponent from "../../components/content-action/content-action.component";
import UserModel from "../../../core/models/user/user.model";
import Moment from "moment";
import { GroupTags } from "../../components/group-tag";
import SelectionContextMenu from "../../components/selection-context-menu/selection-context-menu";

interface OwnProps {
  users: UserModel[];
  onSelectGroup?: (data: UserModel[]) => void;
  onSelectNotification?: (data: UserModel[]) => void;
}

type Props = OwnProps;

const UsersOverviewScreen = (props: Props) => {
  const { t } = useTranslation();
  const { users, onSelectGroup, onSelectNotification } = props;
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>("");

  const columns: ColumnsType<UserModel> = [
    {
      key: "name",
      title: Capitalize(t("form.items.username.label")),
      dataIndex: "name",
      ellipsis: true,
      width: 120,
      ...GetColumnSearchProps({ dataIndex: "name" }),
    },
    {
      key: "email",
      title: Capitalize(t("form.items.email.label")),
      dataIndex: "email",
      ellipsis: true,
      width: 120,
      ...GetColumnSearchProps({ dataIndex: "email" }),
    },
    {
      key: "userGroupAcl",
      title: Capitalize(t("containers.user-groups.key_plural")),
      width: 250,
      render: (_, data) => <GroupTags groupNames={data.userGroups} />,
    },
    {
      key: "created_at",
      title: (
        <Typography.Text ellipsis style={{ width: 120 }}>
          {Capitalize(t("common.created-at"))}
        </Typography.Text>
      ),
      dataIndex: "created_at",
      width: 120,
      sorter: (a, b) => +new Date(a.created_at) - +new Date(b.created_at),
      render: function renderText(_, data) {
        return (
          <Typography.Text>
            {Moment(data.created_at).format("DD/MM/YYYY")}
          </Typography.Text>
        );
      },
    },
    {
      dataIndex: "action",
      width: 1,
      render: function showActions(_, data) {
        return <Space size={"middle"}>{handleShowActions(data)}</Space>;
      },
    },
  ];

  const rowSelection: TableRowSelection<UserModel> = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
    ],
  };

  return (
    <>
      <SelectionContextMenu
        selectedRowKeys={selectedRowKeys}
        onClearSelection={() => setSelectedRowKeys([])}
        actions={[
          {
            key: "notification",
            label: `${Capitalize(t("common.create"))} ${t(
              "containers.notifications.key",
            )}`,
            icon: <ContainerIconComponent screen={"notifications"} />,
            onClick: (selectedRowKeys) => {
              if (!onSelectNotification) return;

              onSelectNotification(
                users.filter((u) => selectedRowKeys.includes(u.username)),
              );
            },
          },
          {
            key: "group",
            label: `${Capitalize(t("common.create"))} ${t(
              "containers.user-groups.key",
            )}`,
            icon: <ContainerIconComponent screen={"user-groups"} />,
            onClick: (selectedRowKeys) => {
              if (!onSelectGroup) return;

              onSelectGroup(
                users.filter((u) => selectedRowKeys.includes(u.username)),
              );
            },
          },
        ]}
      />
      <Table<UserModel>
        dataSource={users}
        columns={columns}
        rowKey={(data) => data.username}
        rowSelection={rowSelection}
        pagination={{ showSizeChanger: true }}
        tableLayout={"fixed"}
        scroll={{ x: true }}
        size={"middle"}
      />
    </>
  );

  function handleShowActions(data: UserModel) {
    return (
      <Link to={{ pathname: `/users/${data.username as string}` }}>
        <ContentActionComponent
          icon={<ContextActionIconComponent action={"open"} />}
        />
      </Link>
    );
  }
};

export default UsersOverviewScreen;
